import {observer} from "mobx-react";
import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import AccountNavigateButton from "../../atoms/AccountNavigateButton";
import {Avatar, InputAdornment} from "@mui/material";
import {path} from "../../../routers/routers";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import settings_bg from '../../assets/images/settingsBackground.png';
import {AddImageButton} from "../../atoms/AddImageButton";
import {BigButton} from "../../atoms/BigButton";
import TextField from "@mui/material/TextField";
import {MyTextField} from "../../atoms/MyTextField";
import {TickCircle, ArrowDown2} from "iconsax-react";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    return <Root>
        <MyRow style={{
            padding: '0 16px'
        }}>
            <BackButton to={path.account}/>
            <p>Edit Profile</p>
        </MyRow>


        <SettingsHeader style={{
            backgroundImage: `url(${settings_bg})`
        }}>
            <AddBackGroundButtonPosition>
                <AddImageButton/>
            </AddBackGroundButtonPosition>

            <AvatarPosition>
                <Avatar
                    src={store.account.details.get().avatar}
                    sx={{width: 96, height: 96}}
                />
                <AddAvatarButtonPosition>
                    <AddImageButton/>
                </AddAvatarButtonPosition>
            </AvatarPosition>
        </SettingsHeader>

        <EditForm>

            <MyTextField
                fullWidth
                label="Username"
                variant="filled"
                type={'text'}
                value={store.account.details.get().username}
                InputProps={{
                    autoFocus: false,
                    autoComplete: 'off',

                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <TickCircle
                                size="24"
                                color="#2BCBBA"
                                variant="Bold"
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <MyTextField
                fullWidth
                label="Name"
                variant="filled"
                type={'text'}
                value={store.account.details.get().name}
                InputProps={{
                    autoComplete: 'off',

                    autoFocus: false,
                    disableUnderline: true,
                }}
            />

            <MyTextField
                fullWidth
                label="Surname"
                variant="filled"
                type={'text'}
                value={store.account.details.get().surname}
                InputProps={{
                    autoComplete: 'off',

                    autoFocus: false,
                    disableUnderline: true,
                }}
            />

            <MyTextField
                fullWidth
                label="Additional name"
                variant="filled"
                type={'text'}
                InputProps={{
                    autoComplete: 'off',

                    autoFocus: false,
                    disableUnderline: true,
                }}
            />

            <MyTextField
                fullWidth
                label="Speciality"
                variant="filled"
                type={'text'}
                value={'Project Owner'}
                InputProps={{
                    autoComplete: 'off',

                    autoFocus: false,
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <ArrowDown2
                                size="24"
                                color="#262626"
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <BigButton
                variant={'contained'}
                color={'primary'}
                fullWidth
            >
                Save Changes
            </BigButton>
        </EditForm>
    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const settings = [template];

const Root = styled.div`
  width: 100%;
  padding: 16px 0 ;
`;
const SettingsHeader = styled.div`
  width: 100%;
  height: 96px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 16px;
  box-shadow: rgba(127, 146, 160, 0.15) 0px 7px 29px 0px;
`;
const AddBackGroundButtonPosition = styled.div`
  position: absolute;
  bottom: -16px;
  right: 16px;
`;
const AvatarPosition = styled.div`
  position: absolute;
  bottom: -48px;
  left: 16px;
`;

const AddAvatarButtonPosition = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
`;

const EditForm = styled.form`
  margin-top: 77px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

