import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import {Calendar, ToggleOffCircle, ToggleOnCircle} from "iconsax-react";
import {BottomPos} from "./BottomPos";
import {BigButton} from "./BigButton";
import styled from "styled-components";
import {Context} from "../../context";
import {path} from "../../routers/routers";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type Props = {
    id: number;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
}

const StartQuizDrawer = ({
                             id,
                             setOpen,
                             open
                         }: Props) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const ref = useRef<HTMLInputElement>(null);

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (ref.current) {
            ref.current.showPicker();
        }
    };

    const ref2 = useRef<HTMLInputElement>(null);

    const handleClick2 = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (ref2.current) {
            ref2.current.showPicker();
        }
    };

    const [ordering, setOrdering] = useState<string>('');
    const [type, setType] = useState<number>(1);
    // const [expected_participant, setExpectedParticipant] = useState<boolean>(false);
    const [use_code, setUseCode] = useState<boolean>(true);
    const [expected_date, setExpected_date] = useState<string>(getStartDateTime());
    const [expected_end_date, setExpected_end_date] = useState<string>(getEndDateTime());

    useEffect(() => {
        if (expected_date.trim() !== '' && expected_end_date.trim() !== '') {
            if (Date.parse(expected_end_date) < Date.parse(expected_date)) {
                let tzoffset3 = (new Date(Date.parse(expected_date)).getTimezoneOffset() * 61000); //offset in milliseconds
                let localISOTime2 = (new Date(Date.parse(expected_date) - tzoffset3)).toISOString().slice(0, -1);
                setExpected_end_date(localISOTime2)
            }
        }
    }, [expected_date, expected_end_date])


    function getStartDateTime() {
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().split('.')[0];
        // console.log(localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1])
        return localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1];
    }

    function setStartDateTime(val: any) {
        console.log(typeof val)
        setExpected_date(val)
    }

    function getEndDateTime() {
        let tzoffset = (new Date()).getTimezoneOffset() * 61000; //offset in milliseconds
        let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().split('.')[0];
        // console.log(localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1])
        return localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1];
    }

    function setEndDateTime(val: any) {
        console.log(typeof val)
        setExpected_end_date(val)
    }


    const startQuiz = () => {
        if (id !== null && !isNaN(id)) {
            store.quiz.new_session.request({id}, {
                type,
                expected_date: expected_date.trim() === "" ? null : expected_date,
                use_code,
                expected_participant: 0,
                expected_end_date: expected_end_date.trim() === "" ? null : expected_end_date,
                winner_number: 1
            }).then(r => {
                console.log(r)

                switch (r.status) {
                    case 201:
                        navigate(`${path.room}/${store.quiz.new_session.get().id}`)
                        break;
                }
            })
        }
    }


    return (
        <SwipeableEdgeDrawer open={open} setOpen={setOpen} label={'Начать'}>
            <StartContainer>
                <StartLabel onClick={() => {
                    if (type === 1) {
                        setType(2)
                    } else {
                        setType(1)
                    }
                }}>
                    <StartLabelTitle>Начинать синхронно</StartLabelTitle>

                    {
                        type === 1 ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }

                </StartLabel>

                {/*<StartLabel onClick={() => {*/}
                {/*    setExpectedParticipant(!expected_participant)*/}
                {/*}}>*/}
                {/*    <StartLabelTitle>Автоматически задавать вопросы</StartLabelTitle>*/}
                {/*    {*/}
                {/*        expected_participant ?*/}
                {/*            <ToggleOnCircle*/}
                {/*                size="24"*/}
                {/*                color="#2BCBBA"*/}
                {/*            />*/}
                {/*            : <ToggleOffCircle*/}
                {/*                size="24"*/}
                {/*                color="#7F92A0"*/}
                {/*            />*/}
                {/*    }*/}

                {/*</StartLabel>*/}

                <StartLabel onClick={() => {
                    setUseCode(!use_code)
                }}>
                    <StartLabelTitle>Использовать код</StartLabelTitle>
                    {
                        use_code ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }
                </StartLabel>

                <StartLabel onClick={handleClick}>
                    <StartLabelTitle>Дата и время начала квиза</StartLabelTitle>

                    {/*toLocaleDateString()*/}
                    <InputLabel>
                        {/*<div>{new Date(expected_date).toLocaleDateString() + ', ' + new Date(expected_date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</div>*/}
                        <input
                            min={getStartDateTime()}
                            ref={ref}
                            style={{
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                textAlign: 'right',
                                // width: '10px'
                            }}
                            value={expected_date}
                            onChange={(e) => {
                                setStartDateTime(e.target.value)
                            }} type="datetime-local"
                            required/>
                        <Calendar
                            size="24"
                            color="#7F92A0"
                        />
                    </InputLabel>
                </StartLabel>

                <StartLabel onClick={handleClick2}>
                    <StartLabelTitle>Дата и время конец квиза</StartLabelTitle>
                    <InputLabel>
                        <input
                            min={getEndDateTime()}
                            ref={ref2}
                            style={{
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                textAlign: 'right'
                            }}
                            onChange={(e) => {
                                setEndDateTime(e.target.value)
                            }}
                            value={expected_end_date}
                            type="datetime-local"
                            required/>
                        <Calendar
                            size="24"
                            color="#7F92A0"
                        />
                    </InputLabel>

                </StartLabel>

                <BottomPos>
                    <BigButton
                        fullWidth
                        variant="contained"
                        loading={store.quiz.new_session.loader.get()}
                        onClick={() => startQuiz()}
                        sx={{
                            zIndex: '99999999'
                        }}
                    >
                        Начать
                    </BigButton>
                </BottomPos>

            </StartContainer>
        </SwipeableEdgeDrawer>
    );
};

export default observer(StartQuizDrawer);


const StartContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
  padding: 24px 16px 100px 16px;
`;

const StartLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  //flex-wrap: wrap;
`;
const StartLabelTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: var(--gray);
  user-select: none;
  -webkit-user-select: none;

`;

const InputLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  //width: 50%;
`;