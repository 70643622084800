import React from 'react';
import {Skeleton} from "@mui/material";
import styled from "styled-components";

const QuestionFormSkeleton = () => {
    return (
        <Root>
            <Skeleton variant="rounded" width={`100%`}
                      height={194}/>
            <Skeleton variant="rounded" width={`100%`}
                      height={48}/>
            <Skeleton variant="rounded" width={`100%`}
                      height={48}/>
            <Skeleton variant="rounded" width={`100%`}
                      height={48}/>
            <Skeleton variant="rounded" width={`100%`}
                      height={48}/>
        </Root>
    );
};

export default QuestionFormSkeleton;

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;