import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import styled from "styled-components";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import React, {useContext, useEffect, useState} from "react";
import {BigButton} from "../../atoms/BigButton";
import {Context} from "../../../context";
import {IError, useErrors} from "../../../hooks/useErrors";
import QuizFormInput from "../../atoms/QuizFormInput";
import {HiddenText} from "../../atoms/HiddenText";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const Body = observer(() => {

    const gaEventTracker = useAnalyticsEventTracker('Create Quiz');


    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    //category params
    const [mainValue, setMainValue] = useState<string>('');
    const [mainId, setMainId] = useState<number>(NaN);
    //category params end

    const error_array: IError[] = [
        {field: 'name', error: false},
        {field: 'category', error: false},
        {field: 'description', error: false},
        {field: 'question_time', error: false},
    ]

    const {errors, getError, findErrorFromArray} = useErrors(error_array, true)

    //inputs values
    const [disabled, setDisabled] = useState(true);
    const [image, setImage] = useState<any>(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>('');
    const [question_time, setQuestion_time] = useState<string>('');
    const [reward, setReward] = useState<string>('');

    useEffect(() => {
        if (
            name.trim() !== ''
            && !isNaN(Number(question_time))
            && Number(question_time) > 0
            && !isNaN(mainId)
        ) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [name, question_time, reward, mainId])
    //inputs values

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(image)
        store.quiz.create.request(image !== null ? {
            name,
            description,
            question_time: !isNaN(Number(question_time)) ? Number(question_time) : 0,
            reward,
            category: mainId,
            add_images: [image, image]
        } : {
            name,
            description,
            question_time: !isNaN(Number(question_time)) ? Number(question_time) : 0,
            reward,
            category: mainId,
        }).then(r => {
            console.log(r)

            if (r.status === 201) {
                navigate(`${path.quiz}/${store.quiz.create.get().id}/${path.questions.split('/')[1]}${path.create}`)
                gaEventTracker(`quiz ${name} is created by ${store.account.details.get().username}: ${r.status}`)
            } else {
                findErrorFromArray(r.data)
                gaEventTracker(`cant  create quiz ${name} : ${r.status}`)
            }
        })
    };

    return <Root>
        <MyRow>
            <BackButton to={`${path.quiz}`}/>
            <HiddenText>{t("quiz.create.button.back")}</HiddenText>
        </MyRow>

        <Form onSubmit={handleSubmit}>

            <QuizFormInput
                image={image}
                setImage={setImage}
                name={name}
                setName={setName}
                mainValue={mainValue}
                setMainValue={setMainValue}
                mainId={mainId}
                setMainId={setMainId}
                description={description}
                question_time={question_time}
                setQuestion_time={setQuestion_time}
                setDescription={setDescription}
                getError={getError}
                reward={reward}
                setReward={setReward}
            />

            <BigButton
                sx={{
                    margin: '48px 0'
                }}
                fullWidth
                disabled={disabled}
                type={'submit'}
                variant="contained"
                color={'primary'}
                loading={store.quiz.create.loader.get()}
            >
                {t("quiz.create.button.submit")}
            </BigButton>
        </Form>
    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const create_quiz = [template];

const Root = styled.div`
  width: 100%;
  padding: 16px;
`;

const Form = styled.form`
  margin-top: 16px;
  width: 100%;
`;
