import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {ID} from "../models/@others/ID";
import {ISendAnswer} from "../models/quiz_session_sync/params/ISendAnswer";
import {ITimerResponse} from "../models/quiz_session_sync/responses/ITimerResponse";
import {IShowQuestionResponse} from "../models/quiz_session_sync/responses/IShowQuestionResponse";
import {QuizSessionAsync} from "../services/quiz_session_async";


export class QuizSessionAsyncStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly answer = new ServiceStore<[ID, ISendAnswer], any>(QuizSessionAsync.answer, false);
    public readonly check_timer = new ServiceStore<[ID], ITimerResponse>(QuizSessionAsync.check_timer, false);
    public readonly is_answered = new ServiceStore<[ID], any>(QuizSessionAsync.is_answered, false);
    public readonly next_question = new ServiceStore<[ID], any>(QuizSessionAsync.next_question, false);
    public readonly show_question = new ServiceStore<[ID], IShowQuestionResponse>(QuizSessionAsync.show_question, true);
    public readonly start_timer = new ServiceStore<[ID], any>(QuizSessionAsync.start_timer, false);
}