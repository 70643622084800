import styled from "styled-components";
import icon from '../assets/images/AddImageButton.svg';

export const AddImageButton = styled.div`
  background-image: url(${icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;