import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import FilterDrawer from "../FilterDrawer";
import styled from "styled-components";
import {ToggleOffCircle, ToggleOnCircle} from "iconsax-react";
import {useTranslation} from "react-i18next";

type Props = {
    history: boolean | null;
    setIHistory: Dispatch<SetStateAction<boolean | null>>;

    i_part: boolean | null;
    setIPart: Dispatch<SetStateAction<boolean | null>>;

}

const SessionFilters = ({i_part, setIPart, setIHistory, history}: Props) => {
    const {t} = useTranslation();

    return (
        <div>
            <FilterDrawer active={history !== null}>
                <StartLabel onClick={() => {
                    if (i_part) {
                        setIPart(null)
                        setIHistory(null)
                    } else {
                        setIPart(true)
                    }
                }}>
                    <StartLabelTitle>Я участвую / участвовал</StartLabelTitle>
                    {
                        i_part ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }

                </StartLabel>


                <StartLabel onClick={() => {
                    if (history) {
                        setIHistory(null)
                    } else {
                        setIHistory(true)
                        setIPart(true)
                    }
                }}>
                    <StartLabelTitle>История</StartLabelTitle>

                    {
                        history ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }

                </StartLabel>


            </FilterDrawer>
        </div>
    );
};

export default observer(SessionFilters);

const StartLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const StartLabelTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: var(--gray);
  user-select: none;
  -webkit-user-select: none;
`;
