export const fast_auth = {
    post() {
        return `/v1.0/api/account/fast_auth/`;
    },
}
export const account = {
    get() {
        return `/v1.0/api/account/`;
    },
}
