import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {MyRow} from "../../MyRow";
import BackButton from "../../BackButton";
import {path} from "../../../../routers/routers";
import FinishSession from "./FinishSession";
import Typography from "@mui/material/Typography";
import SessionParticipant from "../SessionParticipant";
import Ads from "../../Ads";
import TextField from "@mui/material/TextField";
import {BigButton} from "../../BigButton";
import styled from "styled-components";
import {Context} from "../../../../context";
import {useNavigate} from "react-router-dom";
import {IError, useErrors} from "../../../../hooks/useErrors";

type Props = {
    reconnect: boolean;
    finishSession: boolean;
    setReconnect: Dispatch<SetStateAction<boolean>>;
    showQuestionAsync: () => void;
}

const ClientRoom = ({reconnect, finishSession, setReconnect, showQuestionAsync}: Props) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [is_start, setIsStart] = useState<boolean>(false);

    useEffect(() => {
        let date1 = new Date(store.quiz_session.details.get().expected_date).getTime()
        let date2 = new Date(getStartDateTime()).getTime()
        let date3 = new Date(store.quiz_session.details.get().expected_end_date).getTime()

        if (date1 <= date2 && date2 <= date3) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
        // console.log(store.quiz_session.details.get().expected_end_date)

    }, [])

    function getStartDateTime() {
        let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().split('.')[0];
        // console.log(localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1])
        return localISOTime.split(':')[0] + ':' + localISOTime.split(':')[1];
    }


    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        if (localStorage.getItem('user_quiz_name') !== null) {
            setName(localStorage.getItem('user_quiz_name')!)
        }
    }, [])

    const error_array: IError[] = [
        {field: 'name', error: false},
        {field: 'code', error: false},
    ]

    const {errors, getError, findErrorFromArray} = useErrors(error_array, false)

    const connect = (event: any) => {
        event.preventDefault();
        store.quiz_session.connect.request({id: store.quiz_session.details.get().id}, {
            code: store.quiz_session.details.get().is_code ? code : null,
            name: name.trim() === '' ? 'anonymous user' : name
        }).then(r => {
            if (r.status === 201) {
                localStorage.setItem('user_quiz_name', name)
                setReconnect(true)
            } else {
                findErrorFromArray(r.data)
            }
        })
    };

    const connectAsync = (event: any) => {
        event.preventDefault();
        setLoading(true)
        store.quiz_session.connect.request({id: store.quiz_session.details.get().id}, {
            code: store.quiz_session.details.get().is_code ? code : null,
            name: name.trim() === '' ? 'anonymous user' : name
        }).then(r => {
            setLoading(false)
            if (r.status === 201) {
                localStorage.setItem('user_quiz_name', name)
                setIsStart(true)
            } else {
                findErrorFromArray(r.data)
            }
        })
    };

    const next = () => {
        setLoading(true)
        store.quiz_session_async.next_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            setLoading(false)
            if (r.status == 200) {
                store.quiz_session_async.start_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                    console.log(r)

                    if (r.status == 200) {

                        setReconnect(true)

                        store.quiz_session_async.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setTimer(r.data.timer)
                            }
                        })

                        showQuestionAsync()

                    }
                })
            } else if (r.status == 204) {
                store.quiz_session.show_result.request({id: store.quiz_session.details.get().id}, {}).then(r => {
                    console.log(r)
                    if (r.status === 200) {
                        // setShowResult(true)
                    }
                })
            }
        })
    }

    return (
        <Root>

            <Top>
                <MyRow>
                    <BackButton to={path.session} color={'#fff'}/>
                    <p style={{color: '#fff'}}>{store.quiz_session.details.get().quiz.name}</p>
                </MyRow>

                <Prize>
                    Приз {store.quiz_session.details.get().reward}
                </Prize>

                <MyRow style={{
                    justifyContent: 'center'
                }}>
                    <p style={{
                        fontSize: '16px',
                        color: '#fff',
                        marginTop: '12px',
                        width: '90%',
                        textAlign: 'center',
                        wordBreak: 'break-all'
                    }}>
                        {store.quiz_session.details.get().quiz.description}
                    </p>
                </MyRow>

            </Top>


            {finishSession ? <FinishSession/>
                :
                <Container>
                    {
                        reconnect ? <>

                                {
                                    store.quiz_session.details.get().type === 1 ? <Titles>
                                            <Typography sx={{
                                                alignSelf: 'center',
                                                fontWeight: '700',
                                                fontSize: '24px'
                                            }}>
                                                Ожидайте начала

                                            </Typography>
                                            <Typography sx={{
                                                alignSelf: 'center',
                                                // color: 'text.secondary',
                                                fontSize: '16px',
                                                width: '50%',
                                                textAlign: 'center'
                                            }}>
                                                Квиз скоро начнется
                                            </Typography>
                                            <div>
                                                Количество участников: <SessionParticipant
                                                defaultValue={store.quiz_session.details.get().participant_number}
                                            />
                                            </div>
                                        </Titles> :

                                        is_start ?
                                            <Titles>
                                                <Typography sx={{
                                                    alignSelf: 'center',
                                                    fontWeight: '700',
                                                    fontSize: '24px'
                                                }}>
                                                    Вы подключилсь

                                                </Typography>
                                                <Typography sx={{
                                                    alignSelf: 'center',
                                                    // color: 'text.secondary',
                                                    fontSize: '16px',
                                                    width: '50%',
                                                    textAlign: 'center'
                                                }}>
                                                    {disabled ? 'Время квиза закончилось. Вы не сможете начать опрос' : 'Чтобы начать опрос нажмите продолжить'}
                                                </Typography>

                                                <BigButton
                                                    disabled={disabled}
                                                    onClick={next}
                                                    fullWidth
                                                    variant="contained"
                                                    loading={loading}
                                                >
                                                    продолжить
                                                </BigButton>

                                            </Titles>
                                            :
                                            <Titles>
                                                <Typography sx={{
                                                    alignSelf: 'center',
                                                    fontWeight: '700',
                                                    fontSize: '24px'
                                                }}>
                                                    Произшла ошибка

                                                </Typography>
                                                <Typography sx={{
                                                    alignSelf: 'center',
                                                    // color: 'text.secondary',
                                                    fontSize: '16px',
                                                    width: '50%',
                                                    textAlign: 'center'
                                                }}>
                                                    {disabled ? 'Время квиза заончилось. Вы не сможете подключиться' : 'Попробуйте подключиться повторно'}
                                                </Typography>

                                                <BigButton
                                                    disabled={disabled}
                                                    onClick={next}
                                                    fullWidth
                                                    variant="contained"
                                                    loading={loading}
                                                >
                                                    Начать
                                                </BigButton>

                                            </Titles>
                                }

                                <Ads/>

                            </> :
                            is_start ?
                                <Titles>
                                    <Typography sx={{
                                        alignSelf: 'center',
                                        fontWeight: '700',
                                        fontSize: '24px'
                                    }}>
                                        Вы подключилсь

                                    </Typography>
                                    <Typography sx={{
                                        alignSelf: 'center',
                                        // color: 'text.secondary',
                                        fontSize: '16px',
                                        width: '50%',
                                        textAlign: 'center'
                                    }}>
                                        {disabled ? 'Время квиза закончилось. Вы не сможете начать опрос' : 'Чтобы начать опрос нажмите продолжить'}
                                    </Typography>

                                    <BigButton
                                        disabled={disabled}
                                        onClick={next}
                                        fullWidth
                                        variant="contained"
                                        loading={loading}
                                    >
                                        продолжить
                                    </BigButton>

                                </Titles> :
                                <>

                                    <Titles>
                                        <Typography sx={{
                                            alignSelf: 'center',
                                            fontWeight: '700',
                                            fontSize: '24px'
                                        }}>
                                            Комната #{store.quiz_session.details.get().id}
                                        </Typography>
                                        <Typography sx={{
                                            alignSelf: 'center',
                                            color: 'text.secondary',
                                            fontSize: '16px'
                                        }}>
                                            Выведите код и имя чтобы войти
                                        </Typography>

                                    </Titles>

                                    <TextField
                                        error={getError('name')}
                                        label={"Имя"}
                                        type={'text'}

                                        fullWidth
                                        variant={'standard'}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />


                                    {store.quiz_session.details.get().is_code && <TextField
                                        error={getError('code')}
                                        label={"Код"}
                                        type={'text'}

                                        fullWidth
                                        variant={'standard'}
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />}

                                    {
                                        store.quiz_session.details.get().type === 1 ? <BigButton
                                                onClick={connect}
                                                fullWidth
                                                variant="contained"
                                                loading={store.quiz_session.connect.loader.get()}
                                            >
                                                Войти
                                            </BigButton>
                                            :
                                            <BigButton
                                                disabled={disabled}
                                                onClick={connectAsync}
                                                fullWidth
                                                variant="contained"
                                                loading={loading}
                                            >
                                                Войти
                                            </BigButton>
                                    }


                                    <div style={{
                                        width: '100%'
                                    }}>Количество участников: <SessionParticipant
                                        defaultValue={store.quiz_session.details.get().participant_number}
                                    /></div>

                                    <div style={{
                                        width: '100%',
                                    }}>Вопросов: {store.quiz_session.details.get().question_number}</div>

                                    {
                                        store.quiz_session.details.get()?.expected_date && <div style={{
                                            width: '100%',
                                        }}>Дата
                                            начала: <span>{new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[0]
                                                + ', ' + new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[1].split(':')[0]
                                                + ':' + new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[1].split(':')[1]
                                            }</span>
                                        </div>
                                    }
                                    {
                                        store.quiz_session.details.get()?.expected_end_date && <div style={{
                                            width: '100%',
                                        }}>Дата
                                            окончания: <span>{new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[0]
                                                + ', ' + new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[1].split(':')[0]
                                                + ':' + new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[1].split(':')[1]
                                            }</span>
                                        </div>
                                    }
                                </>
                    }

                </Container>

            }

        </Root>
    );
};

export default observer(ClientRoom);


const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Top = styled.div`
  width: 100%;
  position: relative;
  height: 250px;
  padding: 24px 16px;
  background-color: #1DA1F2;
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding: 16px 16px;
  gap: 24px;
`;

const Titles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Prize = styled.div`
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
  word-break: break-word;
  margin-top: 16px;
`;