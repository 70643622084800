import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../context";
import Questions from "../../Questions";

type Props = {
    showQuestionAsync: () => void;
}

const ClientShowQuestion = ({showQuestionAsync}: Props) => {
    const {store} = useContext(Context);

    const [answer, setAnswer] = useState<string>('');
    const [timer, setTimer] = useState<number>(0)
    const [isAnswered, setIsAnswered] = useState<boolean>(false)

    useEffect(() => {
        if (store.quiz_session.details.get().type === 1) {
            store.quiz_session_sync.is_answered.request({id: store.quiz_session.details.get().id}).then(r => {
                console.log(r)
                if (r.status === 200) {
                    setIsAnswered(false)
                } else {
                    setIsAnswered(true)
                }
            })

            store.quiz_session_sync.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                console.log(r)
                if (r.status === 200) {
                    setTimer(r.data.timer)
                }
            })

        } else if (store.quiz_session.details.get().type === 2) {
            store.quiz_session_async.is_answered.request({id: store.quiz_session.details.get().id}).then(r => {
                console.log(r)
                if (r.status === 200) {
                    setIsAnswered(false)
                } else {
                    setIsAnswered(true)
                }
            })
            store.quiz_session_async.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                console.log(r)
                if (r.status === 200) {
                    setTimer(r.data.timer)
                }
            })
        }
    }, [])


    const sendAnswerSync = (answer: number | string) => {
        store.quiz_session_sync.answer.request({id: store.quiz_session.details.get().id}, {
            answer: answer,
        }).then(r => {
            console.log(r)
            if (r.status === 200) {
                setIsAnswered(true)
                setAnswer('')
            } else {
                setIsAnswered(false)
            }
        })
    }

    const sendAnswerAsync = (answer: number | string) => {
        store.quiz_session_async.answer.request({id: store.quiz_session.details.get().id}, {
            answer: answer,
        }).then(r => {
            console.log(r)
            if (r.status === 200) {
                setIsAnswered(true)
                setAnswer('')
            } else {
                setIsAnswered(false)
            }
        })
    }

    const nextQuestionAsync = (e: any) => {
        store.quiz_session_async.next_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)

            if (r.status == 200) {
                store.quiz_session_async.start_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                    console.log(r)

                    if (r.status == 200) {
                        store.quiz_session_async.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                setTimer(r.data.timer)
                            }
                        })
                        showQuestionAsync()

                    }
                })
            } else if (r.status == 204) {
                showQuestionAsync()
            }
        })
    }

    return (
        <Questions
            text={store.quiz_session.details.get().type === 1
                ? store.quiz_session_sync.show_question.get().text
                : store.quiz_session_async.show_question.get().text}
            type={store.quiz_session.details.get().type === 1
                ? store.quiz_session_sync.show_question.get().type
                : store.quiz_session_async.show_question.get().type}
            answers={store.quiz_session.details.get().type === 1
                ? store.quiz_session_sync.show_question.get().answers
                : store.quiz_session_async.show_question.get().answers}

            answer={answer}
            setAnswer={setAnswer}
            isAnswered={isAnswered}
            timer={timer}
            setTimer={setTimer}
            sendAnswer={store.quiz_session.details.get().type === 1 ? sendAnswerSync : sendAnswerAsync}
            quiz={store.quiz_session.details.get().quiz.name}
            nextQuestionAsync={nextQuestionAsync}
        />
    );
};

export default observer(ClientShowQuestion);