import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import {Header} from "../../atoms/Header";
import RowCard from "../../atoms/RowCard";
import {path} from "../../../routers/routers";
import * as React from "react";
import BackButton from "../../atoms/BackButton";
import {MyRow} from "../../atoms/MyRow";
import no_data_img from '../../assets/images/no_question_img.svg';
import {BigButton} from "../../atoms/BigButton";
import {useWindowSize} from "usehooks-ts";
import NoData from "../../atoms/NoData";
import {BottomPos} from "../../atoms/BottomPos";
import Scroll from "../../atoms/Scroll";
import StartQuizDrawer from "../../atoms/StartQuizDrawer";
import NotFoundPage from "../../atoms/NotFoundPage";
import {Skeleton} from "@mui/material";
import FilterDrawer from "../../atoms/FilterDrawer";
import QuestionFilters from "../../atoms/filters/QuestionFilters";
import {HiddenText} from "../../atoms/HiddenText";
import {IQuizSessionRow} from "../../../../QMS/models/quiz_session/responses/IQuizSessionRow";
import {IQuizQuestionRow} from "../../../../QMS/models/quiz_question/responses/IQuizQuestionRow";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {height} = useWindowSize()
    const location = useLocation();

    useEffect(() => {
        store.quiz.details.loader.set(true)
        store.quiz_question.list.loader.set(true)

        return () => {
            store.quiz.details.loader.set(true)
            store.quiz_question.list.loader.set(true)
        }
    }, [])

    const [id, setId] = useState<number | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        let _id = Number(location.pathname.split('/')[2])
        if (_id !== null && !isNaN(Number(_id))) {
            setId(_id)
            store.quiz.details.request({id: _id}).then(r => {
                if (r.status === 404) {
                    setNotFound(true)
                }
            })
        }
    }, [location.pathname]);

    const [open, setOpen] = useState(false);
    const [againLoader, setAgainLoader] = useState<boolean>(true);
    const [limit, setLimit] = useState<number>(5);
    const [type, setType] = useState<number | null>(null);
    const [offset, setOffset] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const array = [0, 0, 0]
    const [list, setList] = useState<IQuizQuestionRow[]>([]);

    useEffect(() => {
        if (id !== null && !isNaN(id)) {
            store.quiz_question.list.request({id}, {
                limit,
                offset,
                type: type!,
                search
            }).then(r => {
                console.log(r)
                if (store.quiz_question.list.get().results.length > 0) {
                    setList([...list, ...store.quiz_question.list.get().results])
                }
            })
        }
    }, [id, limit, type, search, offset])

    if (notFound) return <NotFoundPage/>

    return <div>
        <StartQuizDrawer id={id!} open={open} setOpen={setOpen}/>
        <Header>
            <MyRow>
                {
                    store.quiz.details.loader.get() ?
                        <Skeleton variant="rounded" width={`30%`} height={24}/>
                        : <>
                            <BackButton to={path.quiz}/>
                            <HiddenText>{store.quiz.details.get().name}</HiddenText>
                        </>
                }
            </MyRow>
            <QuestionFilters type={type} setType={setType}/>
        </Header>

        <Root length={store.quiz_question.list.get().count}>
            {
                store.quiz.details.loader.get() && store.quiz_question.list.loader.get() && againLoader ? array.map((e, i) =>
                    <RowCard
                        key={i}/>) : store.quiz_question.list.get().count > 0 ?
                    <>
                        <Scroll
                            offset={offset} setOffset={setOffset}
                            length={store.quiz_question.list.get().count}
                            limit={limit} setAgainLoader={setAgainLoader}>
                            {
                                store.quiz_question.list.loader.get() && againLoader ? array.map((e, i) => <RowCard
                                    key={i}/>) : list.map((e, i) =>
                                    <RowCard key={e.id}
                                             title={e.text}
                                             subtitles={[
                                                 {
                                                     main: `${e.type === 1 ? `${t("quiz.question.list.row.subtitle.question.type.1")}`
                                                         : `${t("quiz.question.list.row.subtitle.question.type.2")}`}`,
                                                     body: ''
                                                 }
                                             ]}
                                             onClick={() => navigate(`${path.edit.split('/')[1]}/${e.id}`)}
                                    />
                                )
                            }
                        </Scroll>


                        <BottomPos>
                            <BigButton
                                fullWidth
                                variant="contained"
                                onClick={() => setOpen(true)}
                            >
                                {t("quiz.start")}
                            </BigButton>
                            <BigButton
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                fullWidth
                                variant="outlined"
                                onClick={() => navigate(`${path.create.split('/')[1]}`)}
                            >
                                {t("quiz.question.list.add")}
                            </BigButton>
                        </BottomPos>

                    </>
                    :
                    <NoData
                        height={height}
                        title={t("quiz.question.list.nodata.title")}
                        subtitle={t("quiz.question.list.nodata.subtitle")}
                        src={no_data_img}
                        onClick={() => navigate(`${path.create.split('/')[1]}`)}
                        button_title={t("quiz.question.list.nodata.button")}
                        img_width={'300px'}
                    />
            }
        </Root>
    </div>
});

const template: ITemplate = {
    body: <Body/>
}

export const questions_list = [template];

type RootProps = {
    length: number;
}

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: calc(16px + var(--header-height)) 16px ${({length}: RootProps) => length > 0 ? 'calc(20vh + 100px)' : '16px'} 16px;
`