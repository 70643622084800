import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../context";
import PageLoader from "../../PageLoader";
import CreatorRoom from "./CreatorRoom";
import Centrifuge from "centrifuge";
import {socket} from "../../../../../QMS/endpoints/ws";
import CreatorShowResult from "./CreatorShowResult";
import SyncCreatorShowQuestion from "./sync/SyncCreatorShowQuestion";

const Creator = () => {
    const {store} = useContext(Context);

    const [show_question, setShowQuestions] = useState<boolean>(false);
    const [show_result, setShowResult] = useState<boolean>(false);

    const showQuestion = () => {
        store.quiz_session_sync.show_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setShowQuestions(true)
                setShowResult(false)
            } else if (r.status === 204) {
                setShowQuestions(false)
                setShowResult(true)
                store.quiz_session.calculate_places.request({id: store.quiz_session.details.get().id}).then(r => {
                    console.log(r)
                })
            } else {
                setShowQuestions(false)
                setShowResult(false)
            }
        })
    }

    useEffect(() => {
        if (store.quiz_session.details.get().type === 1) {
            store.quiz_session_sync.show_question.loader.set(true)
            showQuestion();
        } else if (store.quiz_session.details.get().type === 2) {

        }

        const centrifuge = new Centrifuge(socket.connect());
        centrifuge.setToken(store.quiz_session.details.get().cent_token)

        let callbacks = {
            "publish": function (ctx: any) {
                console.log(ctx.data);

                if (store.quiz_session.details.get().type === 1) {

                    if (ctx.data.reason === 'show_question') {
                        showQuestion()
                    }

                } else if (store.quiz_session.details.get().type === 2) {

                }

                if (ctx.data.reason === 'show_result') {
                    setShowResult(true)
                }

            },
        }

        centrifuge.presence(`public:channel`)
        centrifuge.subscribe(`quiz_session.${store.quiz_session.details.get().id}`, callbacks);
        centrifuge.connect()

        return () => {
            if (store.quiz_session.details.get().type === 1) {
                store.quiz_session_sync.show_question.loader.set(true)
            } else if (store.quiz_session.details.get().type === 2) {
            }
        }
    }, [])

    if (store.quiz_session.details.get().type === 1) {
        if (store.quiz_session_sync.show_question.loader.get()) return <PageLoader/>
    } else if (store.quiz_session.details.get().type === 2) {

    }

    if (show_result) return <CreatorShowResult/>
    if (show_question) return <SyncCreatorShowQuestion/>
    return <CreatorRoom/>
};

export default observer(Creator);