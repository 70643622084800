import React from 'react';
import styled from "styled-components";

export const MyRow = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
`;

export const MyRowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;
