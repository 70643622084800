import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import styled from "styled-components";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import React, {useContext, useEffect, useState} from "react";
import {BigButton} from "../../atoms/BigButton";
import {Context} from "../../../context";
import PageLoader from "../../atoms/PageLoader";
import {IError, useErrors} from "../../../hooks/useErrors";
import QuizFormInput from "../../atoms/QuizFormInput";
import SkeletonForm from "../../atoms/SkeletonForm";
import NotFoundPage from "../../atoms/NotFoundPage";
import {HiddenText} from "../../atoms/HiddenText";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        store.quiz.details.loader.set(true)
        return () => store.quiz.details.loader.set(true)
    }, [])

    //category params
    const [mainValue, setMainValue] = useState<string>('');
    const [mainId, setMainId] = useState<number>(NaN);
    //category params end

    const error_array: IError[] = [
        {field: 'name', error: false},
        {field: 'category', error: false},
        {field: 'description', error: false},
        {field: 'question_time', error: false},
    ]

    const {errors, getError, findErrorFromArray} = useErrors(error_array, true)

    //inputs values
    const [disabled, setDisabled] = useState(true);
    const [image, setImage] = useState<any>(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState<string>('');
    const [question_time, setQuestion_time] = useState<string>('');
    const [reward, setReward] = useState<string>('');

    useEffect(() => {
        if (
            name.trim() !== ''
            && !isNaN(Number(question_time))
            && Number(question_time) > 0
            && !isNaN(mainId)
        ) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [name, question_time, reward, mainId])
    //inputs values

    //get input values
    const [id, setId] = useState<number | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        let _id = Number(location.pathname.split('/')[2])
        if (_id !== null && !isNaN(Number(_id))) {
            setId(_id)
            store.quiz.details.request({id: _id}).then(r => {
                console.log(r)
                switch (r.status) {
                    case 200:
                        setName(store.quiz.details.get().name)
                        setDescription(store.quiz.details.get().description)
                        setQuestion_time(store.quiz.details.get().question_time.toString())
                        setReward(store.quiz.details.get().reward)
                        setMainId(store.quiz.details.get().category.id)
                        setMainValue(store.quiz.details.get().category.name)
                        setNotFound(false)
                        break;
                    case 404:
                        setNotFound(true)
                        break;
                }
            })
        }
    }, [location.pathname]);

    //get input values
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (id !== null && !isNaN(id)) {
            store.quiz.change.request({id}, image !== null ? {
                name,
                description,
                question_time: !isNaN(Number(question_time)) ? Number(question_time) : 0,
                reward,
                category: mainId,
                // add_images: image ? [image] : null
            } : {
                name,
                description,
                question_time: !isNaN(Number(question_time)) ? Number(question_time) : 0,
                reward,
                category: mainId,
            }).then(r => {
                console.log(r)
                if (r.status === 200) {
                    navigate(path.quiz)
                } else {
                    findErrorFromArray(r.data)
                }
            })
        }
    };

    const Delete = (e: any) => {
        e.preventDefault()
        if (id !== null && !isNaN(id)) {
            store.quiz.delete.request({id}).then(r => {
                console.log(r)
                if (r.status === 200) {
                    navigate(path.quiz)
                } else {
                    alert(r.data[0].message)
                }
            })
        }
    }

    if (notFound) return <NotFoundPage/>

    return <Root>
        <MyRow>
            <BackButton to={`${path.quiz}`}/>
            <HiddenText>{t("quiz.edit.button.back")}</HiddenText>
        </MyRow>

        <Form onSubmit={handleSubmit}>
            {
                store.quiz.details.loader.get() ? <SkeletonForm/> :
                    <>
                        <QuizFormInput
                            image={image}
                            setImage={setImage}
                            name={name}
                            setName={setName}
                            mainValue={mainValue}
                            setMainValue={setMainValue}
                            mainId={mainId}
                            setMainId={setMainId}
                            description={description}
                            question_time={question_time}
                            setQuestion_time={setQuestion_time}
                            setDescription={setDescription}
                            getError={getError}
                            reward={reward}
                            setReward={setReward}
                        />

                        <BigButton
                            sx={{
                                margin: '48px 0 16px 0'
                            }}
                            fullWidth
                            disabled={disabled}
                            type={'submit'}
                            variant="contained"
                            color={'primary'}
                            loading={store.quiz.change.loader.get()}
                        >
                            {t("quiz.edit.button.submit")}
                        </BigButton>

                        <BigButton
                            fullWidth
                            onClick={Delete}
                            variant="contained"
                            color={'error'}
                            loading={store.quiz.delete.loader.get()}
                        >
                            {t("quiz.edit.button.delete")}
                        </BigButton>
                    </>
            }
        </Form>
    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const edit_quiz = [template];

const Root = styled.div`
  width: 100%;
  padding: 16px;
`;

const Form = styled.form`
  margin-top: 16px;
  width: 100%;
`;