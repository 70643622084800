import React from 'react';
import {BigButton} from "./BigButton";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

type Props = {
    height: number;
    src: string;
    title: string;
    subtitle: string;
    onClick?: () => void;
    button_title?: string;
    img_width: string;
}

const NoData = ({height, src, title, subtitle, onClick, button_title, img_width}: Props) => {
    const navigate = useNavigate();

    return (
        <Root height={height}>
            <Image src={src} img_width={img_width}/>

            <Titles>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Titles>

            {
                button_title && <BigButton
                    fullWidth
                    variant="contained"
                    onClick={onClick}
                >
                    {button_title}
                </BigButton>
            }


        </Root>
    );
};

export default observer(NoData);

type RootProps = {
    height: number;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(${({height}: RootProps) => height}px - var(--header-height) - 144px);
`

type ImageProps = {
    img_width: string;
}

const Image = styled.img`
  max-width: ${({img_width}: ImageProps) => img_width};
  width: 100%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: var(--black);
`

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--gray);
`