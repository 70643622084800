import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../context";
import Centrifuge from "centrifuge";
import {socket} from "../../../../../../QMS/endpoints/ws";
import styled from "styled-components";

const SyncSessionQuestionResults = () => {
    const {store} = useContext(Context);
    const [answers, setAnswers] = useState<any>({});

    useEffect(() => {
        store.quiz_session_sync.check_answers.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setAnswers(r.data.answers)
            }
        })

        const centrifuge = new Centrifuge(socket.connect());
        centrifuge.setToken(store.quiz_session.details.get().cent_token)

        let callbacks = {
            "publish": function (ctx: any) {
                console.log(ctx?.data?.reason);

                if (ctx?.data?.reason === 'question_result') {
                    setAnswers(ctx?.data?.result)
                }
            },
        }

        centrifuge.subscribe(`quiz_session_creator.${store.quiz_session.details.get().id}`, callbacks);
        centrifuge.connect()
    }, [])

    return (
        <div>
            <p style={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#262626',
            }}>
                Результаты:
            </p>

            {store.quiz_session_sync.show_question.get().type === 1 ?

                answers !== undefined && Object.keys(answers).map((e, index) =>
                    <ResultItem key={index}>
                        Людей ответивших {e === "0" ? "A" : e === "1" ? "B" : e === "2" ? "C" : "D"}: {answers[e]}
                    </ResultItem>)
                :

                answers !== undefined && Object.keys(answers).map((e, index) =>
                    <ResultItem key={index}>
                        Людей ответивших <b>{e}</b>: {answers[e]}
                    </ResultItem>)}

            {
                Object.keys(answers).length <= 0 && <div style={{
                    margin: '16px 0',
                }}>
                    Пока нет ответивших
                </div>
            }

        </div>
    );
};

export default observer(SyncSessionQuestionResults);


const ResultItem = styled.div`
  margin: 16px 0;
  width: 100%;
  word-break: break-word;
  font-weight: 400;
  font-size: 16px;
  color: var(--gray);
`