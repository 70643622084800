import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import Timer from "../../../Timer";
import {BigButton} from "../../../BigButton";
import styled from "styled-components";
import {Context} from "../../../../../context";

const SyncSessionNextQuestionButton = () => {
    const {store} = useContext(Context);

    const [disabled, setDisabled] = useState<boolean>(true);
    const [timer, setTimer] = useState<number>(0)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.quiz_session_sync.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setTimer(r.data.timer)
            }
        })
    }, [])

    useEffect(() => {
        if (timer > 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [timer])

    const nextQuestion = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        store.quiz_session_sync.next_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)

            if (r.status == 200) {
                store.quiz_session_sync.start_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                    console.log(r)

                    if (r.status == 200) {

                        store.quiz_session_sync.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setTimer(r.data.timer)
                            }
                        })

                        store.quiz_session_sync.show_question.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setShowQuestions(true)
                            }
                        })

                        store.quiz_session_sync.check_answers.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setAnswers(r.data.answers)
                            }
                        })

                        store.quiz_session.close_registration.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                        })
                    }
                })
            } else if (r.status == 204) {
                store.quiz_session.show_result.request({id: store.quiz_session.details.get().id}, {}).then(r => {
                    console.log(r)
                    if (r.status === 200) {
                        // setShowResult(true)
                        store.quiz_session.calculate_places.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                        })
                    }
                })

            }
        })
    }

    return (
        <>
            <Time>
                {
                    timer > 0 && <>
                        <div>Следующий вопрос можно задать через:</div>
                        <div>
                            <Timer time={timer} setTimer={setTimer}/>
                            {' '}
                            <span>секунд</span>
                        </div>
                    </>
                }

            </Time>
            <BigButton
                onClick={(e: any) => {
                    if (!disabled) {
                        nextQuestion(e)
                    }
                }}
                variant="contained"
                disabled={disabled}
                color={'success'}
                loading={loading}
            >
                Задать следующий вопрос
            </BigButton>
        </>
    );
};

export default observer(SyncSessionNextQuestionButton);

const Time = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;
