import {AxiosResponse} from "axios";
import {$qms} from "../http";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {quiz} from "../endpoints/qms";
import {IQuizRow} from "../models/quiz/responses/IQuizRow";
import {IPost} from "../models/quiz/params/IPost";
import {ID} from "../models/@others/ID";
import {IGetAll} from "../models/quiz/params/IGetAll";
import {IQuizSessionResponse} from "../models/quiz/responses/IQuizSessionResponse";
import {IQuizSession} from "../models/quiz/params/IQuizSession";

export class Quiz {
    static async get_all({
                             limit,
                             offset,
                             ordering,
                             search,
                             question_time_min,
                             question_time_max
                         }: IGetAll): Promise<AxiosResponse<IListDataResponse<IQuizRow>>> {
        return await $qms.get<IListDataResponse<IQuizRow>>(quiz.get_all(), {
            params: {
                limit,
                offset,
                ordering,
                search,
                question_time_min,
                question_time_max
            }
        })
    }

    static async post({
                          name,
                          description,
                          question_time,
                          reward,
                          category,
                          add_images
                      }: IPost): Promise<AxiosResponse<IQuizRow>> {

        let body = new FormData();
        body.append("name", name)
        body.append("description", description)
        body.append("question_time", question_time.toString())
        body.append("reward", reward.toString())
        body.append("category", category.toString())

        if (add_images) {
            for (let i = 0; i < add_images.length; i++) {
                body.append("add_images", add_images[i])
            }
        }

        return await $qms.post<IQuizRow>(quiz.post(), body, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static async delete(
        {id}: ID
    ): Promise<AxiosResponse> {
        return await $qms.delete(quiz.delete(id))
    }

    static async patch(
        {id}: ID,
        {
            name,
            description,
            question_time,
            reward,
            category,
            add_images
        }: IPost): Promise<AxiosResponse<IQuizRow>> {
        return await $qms.patch<IQuizRow>(quiz.patch(id), {
            name,
            description,
            question_time,
            reward,
            category,
            add_images
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    static async get({id}: ID): Promise<AxiosResponse<IQuizRow>> {
        return await $qms.get<IQuizRow>(quiz.get(id))
    }

    static async new_session({id}: ID,
                             {
                                 use_code,
                                 type,
                                 expected_date,
                                 expected_participant,
                                 winner_number,
                                 expected_end_date,
                             }: IQuizSession): Promise<AxiosResponse<IQuizSessionResponse>> {
        return await $qms.post<IQuizSessionResponse>(quiz.new_session(id), {
            use_code,
            type,
            expected_date,
            expected_participant,
            winner_number,
            expected_end_date,
        })
    }

}