import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import PageLoader from "./PageLoader";
import answers from "./Answers";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslation} from "react-i18next";
import {Context} from "../../context";
import {IQuizAnswer} from "../../../QMS/models/quiz_question_answer/params/IQuizAnswer";
import Timer from "./Timer";
import {MyRow} from "./MyRow";
import BackButton from "./BackButton";
import {path} from "../../routers/routers";
import {Header} from "./Header";
import {IconButton} from "@mui/material";
import {Filter} from "iconsax-react";
import {BottomPos} from "./BottomPos";
import {BigButton} from "./BigButton";

type Props = {
    text: string;
    type: number;
    answers: IQuizAnswer[];
    sendAnswer: (answer: number | string) => void;
    isAnswered: boolean;
    answer: string;
    setAnswer: Dispatch<SetStateAction<string>>;
    timer: number;
    setTimer: Dispatch<SetStateAction<number>>;
    quiz: string;
    nextQuestionAsync: (e: any) => void;
}

const Questions = ({
                       text,
                       type,
                       answers,
                       timer,
                       setTimer,
                       sendAnswer,
                       isAnswered,
                       setAnswer,
                       answer,
                       quiz,
                       nextQuestionAsync
                   }: Props) => {
    const {t} = useTranslation();
    const {store} = useContext(Context);

    return (
        <>
            {/*<Header>*/}
            {/*    <MyRow>*/}
            {/*        <BackButton to={path.session}/>*/}
            {/*        <p>{quiz}</p>*/}
            {/*    </MyRow>*/}

            {/*</Header>*/}
            <Root>
                <Container>

                    <Title2>
                        Вопрос {store.quiz_session.details.get().type === 1 ?
                        store.quiz_session_sync.show_question.get().question_index :
                        store.quiz_session_async.show_question.get().question_index}
                    </Title2>

                    <Title>
                        {text}
                    </Title>

                    {type === 1 && answers !== undefined
                        && answers.map((e: any, index: number) =>
                            <div key={e?.id}>
                                {index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'} : {e.text}
                            </div>)}
                </Container>


                <BottomPos style={{
                    padding: '0 0 16px 0',
                }}>
                    {!isAnswered && <Time>
                        <div> Вопрос {store.quiz_session.details.get().type === 1 ?
                            store.quiz_session_sync.show_question.get().question_index :
                            store.quiz_session_async.show_question.get().question_index}/{store.quiz_session.details.get().question_number}</div>
                        <div>Время: <Timer time={timer} setTimer={setTimer}/></div>
                        <div>Ответивших: null</div>
                    </Time>}

                    {timer > 0 && !isAnswered ?
                        type === 1 ?

                            <Buttons>
                                <SquareButton
                                    onClick={() => sendAnswer(0)}
                                    style={{
                                        background: '#1DA1F2'
                                    }}>A</SquareButton>
                                <SquareButton
                                    onClick={() => sendAnswer(1)}
                                    style={{
                                        background: '#00C1C1'
                                    }}>B</SquareButton>
                                <SquareButton
                                    onClick={() => sendAnswer(2)}
                                    style={{
                                        background: '#FC9612'
                                    }}>C</SquareButton>
                                <SquareButton
                                    onClick={() => sendAnswer(3)}
                                    style={{
                                        background: '#FF5A5F'
                                    }}>D</SquareButton>
                            </Buttons>

                            : <Block>
                                <TextField
                                    value={answer} onChange={(e) => {
                                    setAnswer(e.target.value)
                                }}
                                    fullWidth
                                    label={'введите ответ'}
                                    variant="standard"/>
                                <BigButton
                                    onClick={() => sendAnswer(answer)}
                                    fullWidth
                                    variant="contained"
                                >
                                    Продолжить
                                </BigButton>
                            </Block>
                        : <Block>
                            {isAnswered ? <div style={{
                                    fontSize: '24px',
                                    color: '#262626',
                                    textAlign: 'center',
                                    fontWeight: '500'
                                }}>
                                    <p>Спасибо за ответ!</p>
                                </div> :
                                <div style={{
                                    fontSize: '24px',
                                    color: '#262626',
                                    textAlign: 'center',
                                    fontWeight: '500'
                                }}>
                                    <p>Время вышло</p>

                                </div>
                            }

                            {
                                store.quiz_session.details.get().type === 2 && <BigButton
                                    onClick={nextQuestionAsync}
                                    fullWidth
                                    variant="contained"
                                >
                                    Следующий вопрос
                                </BigButton>
                            }

                        </Block>
                    }
                </BottomPos>

            </Root>
        </>
    );
};

export default observer(Questions);

const Title = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  white-space: break-spaces;
  word-break: break-word;
`;

const Title2 = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  white-space: break-spaces;
  word-break: break-word;
`;

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 16px 16px calc(20vh + 50px) 16px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Time = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 311px;
  height: 80px;
  margin: 0 auto;
  color: #7F92A0;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.25);
  border-radius: 6px;

`;

const Bottom = styled.div`
  width: 100%;
`;

const SquareButton = styled.div`
  width: 50%;
  height: 108px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  cursor: pointer;
`;

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
`;
