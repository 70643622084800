import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Divider, InputAdornment} from "@mui/material";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import {BigTextField} from "./BigTextField";
import {CloseCircle} from "iconsax-react";
import {useTranslation} from "react-i18next";

type Props = {
    setSearch: Dispatch<SetStateAction<string>>;
}

const CategorySearch = ({setSearch}: Props) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearch(value)
        }, 500);
        return () => clearTimeout(timer);
    }, [value])

    return (
        <>
            <Root>
                <Input>
                    <BigTextField
                        fullWidth
                        variant="standard"
                        type={'text'}
                        label={`${t("button.search.category")}...`}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        InputProps={{
                            autoComplete: 'off',
                            autoFocus: false,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">

                                    {
                                        value.trim() !== '' && <CloseCircle
                                            size="24"
                                            color="#7F92A0"
                                            variant="Outline"
                                            onClick={() => setValue('')}
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                        />
                                    }


                                </InputAdornment>
                            ),
                        }}
                    />
                </Input>
                <Divider sx={{
                    borderColor: '#7F92A0'
                }}/>
            </Root>
        </>
    );
};

export default observer(CategorySearch);


const Root = styled.div`
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 56px;
  z-index: 999;

`
const Space = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`
const Input = styled.div`
  width: 100%;
  padding: 0 16px;
  background-color: #ffffff;

`
