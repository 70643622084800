import {ITemplate} from "gears-react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Context} from "../../../context";
import * as React from "react";
import {useTranslation} from "react-i18next";
import RowCard from "../../atoms/RowCard";
import {BigHeader, Header} from "../../atoms/Header";
import {path} from "../../../routers/routers";
import logo_img from "../../assets/images/HeaderLogo.svg";
import {MyRowSpaceBetween} from "../../atoms/MyRow";
import {ArrowDown2, Filter, SearchNormal1} from "iconsax-react";
import NoData from "../../atoms/NoData";
import g8 from "../../assets/images/g8.svg";
import {useWindowSize} from "usehooks-ts";
import Scroll from "../../atoms/Scroll";
import CategoryTabs from "../../atoms/CategoryTabs";
import {IconButton} from "@mui/material";
import FilterDrawer from "../../atoms/FilterDrawer";
import SessionFilters from "../../atoms/filters/SessionFilters";
import {IQuizRow} from "../../../../QMS/models/quiz/responses/IQuizRow";
import {IQuizSessionRow} from "../../../../QMS/models/quiz_session/responses/IQuizSessionRow";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {height} = useWindowSize()

    useEffect(() => {
        store.quiz_session.list.loader.set(true)
        return () => store.quiz_session.list.loader.set(true)
    }, [])

    const [againLoader, setAgainLoader] = useState<boolean>(true);

    const [limit, setLimit] = useState<number>(5);
    const [offset, setOffset] = useState<number>(0);

    const [i_part, setIPart] = useState<boolean | null>(null);
    const [history, setIHistory] = useState<boolean | null>(null);

    const array = [0, 0, 0]
    const [list, setList] = useState<IQuizSessionRow[]>([]);

    useEffect(() => {
        reload()
    }, [limit, i_part, history, offset])

    const reload = () => {
        store.quiz_session.list.request({
            limit,
            search: '',
            offset,
            i_part: i_part!,
            history: history!
        }).then(r => {
            console.log(r)
            if (store.quiz_session.list.get().results.length > 0) {
                setList([...list, ...store.quiz_session.list.get().results])
            }
        })
    }

    return <div>
        <BigHeader>
            <MyRowSpaceBetween>
                <Logo src={logo_img}/>
                <Row onClick={() => navigate('/')}>
                    <PageTitle>{t("content.link.quizzes.title")}</PageTitle>
                    <ArrowDown2
                        size="24"
                        color="#1DA1F2"
                    />
                </Row>
            </MyRowSpaceBetween>
            <MyRowSpaceBetween>
                {/*<CategoryTabs/>*/}
                <Row>
                    {/*<IconButton>*/}
                    {/*    <SearchNormal1*/}
                    {/*        size="24"*/}
                    {/*        color="#7F92A0"*/}
                    {/*        variant="Outline"*/}
                    {/*    />*/}
                    {/*</IconButton>*/}
                    {/*<FilterDrawer/>*/}
                    <SessionFilters
                        history={history}
                        setIHistory={setIHistory}
                        i_part={i_part}
                        setIPart={setIPart}
                    />
                </Row>
            </MyRowSpaceBetween>
        </BigHeader>
        <Root>
            {
                store.quiz_session.list.loader.get() && againLoader ?
                    array.map((e, i) => <RowCard
                        key={i}/>)
                    :
                    store.quiz_session.list.get().count > 0 ? <Scroll
                        offset={offset} setOffset={setOffset}
                        length={store.quiz_session.list.get().count}
                        limit={limit} setAgainLoader={setAgainLoader}>
                        {
                            list.map((e, i) =>
                                <RowCard key={e.id}
                                         title={e.quiz.name}
                                         img={'need img'}
                                         subtitles={[
                                             {
                                                 body: 'Date ',
                                                 main: `${e.expected_date !== null
                                                     ? new Date(e.expected_date).toLocaleString().split(',')[0]
                                                     + ' Time ' + new Date(e.expected_date).toLocaleString().split(',')[1].split(':')[0] 
                                                     + ':' +
                                                     new Date(e.expected_date).toLocaleString().split(',')[1].split(':')[1]
                                                     : 'null'}`
                                             },
                                             {body: '#', main: `${e.id}`},

                                         ]}
                                         onClick={() => {
                                             if (history) {
                                                 navigate(`${path.room}/${e.id}${path.history}${path.client}`)
                                             } else {
                                                 navigate(`${path.room}/${e.id}`)
                                             }
                                         }}
                                />
                            )
                        }
                    </Scroll> : <NoData
                        height={height}
                        title={'No session found'}
                        subtitle={'Click to reload list'}
                        src={g8}
                        onClick={() => {
                            reload()
                        }}
                        button_title={'Reload'}
                        img_width={'300px'}
                    />
            }
        </Root>
    </div>

})

const template: ITemplate = {
    body: <Body/>
}

export const session_list = [template]

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: calc(16px + var(--header-height) * 2.144) 16px;
  //padding: calc(var(--header-height) + 16px) 16px;
`

const Logo = styled.img`
  width: 68px;
`;

const PageTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--main);
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;