import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {path} from "../../../../routers/routers";
import {Context} from "../../../../context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PopupButton from "../../PopupButton";
import {PopupContainer, PopupContainerTitle} from "../../PopupComponents";
import {BigButton} from "../../BigButton";

const CloseSessionButton = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);


    const closeSession = () => {
        store.quiz_session.close.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                navigate(`${path.quiz}`)
            }
        })
    }

    return (
        <PopupButton title={'Отменить'} variant={'contained'} setOpen={setOpen} open={open}
                     color={"error"}>
            <PopupContainer>
                <PopupContainerTitle>Вы действительно хотите оменить квиз?</PopupContainerTitle>
                <BigButton
                    onClick={closeSession}
                    fullWidth
                    type={'submit'}
                    variant="contained"
                    color={'error'}
                    loading={store.quiz_session.close.loader.get()}
                >
                    Да
                </BigButton>
                <BigButton
                    fullWidth
                    variant="outlined"
                    color={'primary'}
                    onClick={() => setOpen(false)}
                >
                    Нет
                </BigButton>
            </PopupContainer>

        </PopupButton>
    );
};

export default observer(CloseSessionButton);