import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";

type Props = {
    children: React.ReactNode,
    offset: number;
    limit: number;
    setOffset: Dispatch<SetStateAction<number>>;
    setAgainLoader: Dispatch<SetStateAction<boolean>>
    length: number;
}

const Scroll = ({children, offset, limit, setOffset, setAgainLoader, length}: Props) => {


    useEffect(() => {

        setAgainLoader(false)

        const handleScroll = (event: any) => {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 250)) {
                // you're at the bottom of the page
                if (offset < length) {
                    const timer = setTimeout(() => {
                        setOffset(offset + limit)
                    }, 500)
                    return () => clearTimeout(timer);
                } else {
                    setOffset(length)
                }

            }



        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offset]);


    useEffect(() => {
        if (offset < length) {

            if ((document.body.offsetHeight - 100) <= window.innerHeight) {
                const timer = setTimeout(() => {
                    setOffset(offset + limit)
                    setAgainLoader(false)
                    // console.log(document.body.offsetHeight, window.innerHeight)
                }, 500)

                return () => clearTimeout(timer);

            }
        }

    })

    return (
        <Root>
            {children}
        </Root>
    );
};

export default observer(Scroll);

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`