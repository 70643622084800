import {observer} from "mobx-react";
import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import {MyRow, MyRowSpaceBetween} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import {IconButton} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LiveChat from "../../atoms/LiveChat";
import g10 from '../../assets/images/no_streams_img.svg';
import StreamDetails from "../../atoms/StreamDetails";
import {Message} from "iconsax-react";
import ReactPlayer from 'react-player'

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [is_chat, setIsChat] = useState<boolean>(false);
    const [is_muted, setIsMuted] = useState<boolean>(true);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setIsMuted(false)
    //     }, 1500)
    //     return () => clearTimeout(timer);
    // }, [])

    return <Root>
        <StreamHeader>
            <MyRowSpaceBetween style={{
                padding: '16px',
            }}>
                <MyRow style={{
                    width: '50%'
                }}>
                    <BackButton to={`${path.stream}${path.list}`}/>
                    <NameStream>lofi hip hop radio - beats to relax/study to</NameStream>
                </MyRow>
                <MoreVertIcon sx={{
                    fontSize: '24px',
                    color: '#262626',
                    cursor: 'pointer',
                    flexShrink: 0
                }}/>
            </MyRowSpaceBetween>
        </StreamHeader>
        <Content>
            <Video>
                <ReactPlayer
                    playing={true}
                    url='https://www.youtube.com/watch?v=jfKfPfyJRdk'
                    height={240}
                    width={'100%'}
                    muted={is_muted}
                    controls={true}
                />
                {/*<Image src={g10}/>*/}
                {/*<TitleVideo>*/}
                {/*    Disconnect*/}
                {/*</TitleVideo>*/}
            </Video>

            {is_chat ? <LiveChat setIsChat={setIsChat}/> :
                <>
                    <StreamDetails/>
                    <ChatButton onClick={() => setIsChat(true)}>
                        <Message
                            size="24"
                            color="#fff"
                        />
                    </ChatButton>
                </>
            }

        </Content>


    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const stream_room = [template]


const Root = styled.div`
  width: 100%;
  padding: 0 0 calc(20vh + 30px) 0;
`;
const StreamHeader = styled.div`
  width: 100%;
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 9999;
  background-color: white;
`;
const Video = styled.div`
  width: 100%;
  height: 240px;
  background-color: #262626;
  position: relative;
  //position: fixed;
  //top: 56px;
  //left: 0;

`;

const Content = styled.div`
  width: 100%;
  //margin-top: 296px;
  position: relative;
`;


const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 200px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;

`;

const TitleVideo = styled.div`
  position: absolute;
  top: 81%;
  left: 0;
  width: 100%;
  //transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;

  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`;


const ChatButton = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
`;

const NameStream = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;




