import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Header} from "../../Header";
import {MyRow} from "../../MyRow";
import BackButton from "../../BackButton";
import {path} from "../../../../routers/routers";
import Code from "./Code";
import SessionParticipant from "../SessionParticipant";
import {BottomPos} from "../../BottomPos";
import CloseSessionButton from "./CloseSessionButton";
import styled from "styled-components";
import {Context} from "../../../../context";
import SyncSessionStartButton from "./sync/SyncSessionStartButton";
import Ads from "../../Ads";
import SessionShowResultButton from "./SessionShowResultButton";

const CreatorRoom = () => {
    const {store} = useContext(Context);

    return <Root>

        <Header>
            <MyRow>
                <BackButton to={path.quiz}/>
                <p>{store.quiz_session.details.get().quiz.name}</p>
            </MyRow>
        </Header>

        {store.quiz_session.details.get().code && <Code code={store.quiz_session.details.get().code}/>}

        <Titles>
            <Title>Номер комнаты: <span>#{store.quiz_session.details.get().id}</span></Title>
            <Title>Количество участников: <span> <SessionParticipant
                defaultValue={store.quiz_session.details.get().participant_number}
            /></span></Title>

            {
                store.quiz_session.details.get()?.expected_date && <Title>Дата
                    начала: <span>{new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[0]
                        + ', ' + new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[1].split(':')[0]
                        + ':' + new Date(store.quiz_session.details.get()?.expected_date).toLocaleString().split(',')[1].split(':')[1]
                    }</span>
                </Title>
            }
            {
                store.quiz_session.details.get()?.expected_end_date && <Title>Дата
                    окончания: <span>{new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[0]
                        + ', ' + new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[1].split(':')[0]
                        + ':' + new Date(store.quiz_session.details.get()?.expected_end_date).toLocaleString().split(',')[1].split(':')[1]
                    }</span>
                </Title>
            }

            <Title>Тип квиза: <span>{store.quiz_session.details.get().type === 1 ? 'Синхронный' : 'Асинхронный'}</span></Title>
            <Title>Количество вопросов: <span>{store.quiz_session.details.get().question_number}</span></Title>
            <Title>Приз: <span>{store.quiz_session.details.get().reward}</span></Title>
        </Titles>

        <Ads/>

        <BottomPos>
            {store.quiz_session.details.get().type === 1 ? <SyncSessionStartButton/> : <SessionShowResultButton/>}
            <CloseSessionButton/>
        </BottomPos>

    </Root>
};

export default observer(CreatorRoom);


const Root = styled.div`
  width: 100%;
  padding: var(--header-height) 16px calc(20vh + 50px) 16px;

  @media (max-height: 730px) {
    padding: var(--header-height) 16px calc(30vh + 100px) 16px;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 64px 0;
`

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--gray);

  span {
    font-weight: 500;
    color: var(--black);
  }
`