export const options = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // padding: '14px 30px',

                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&&:hover::before": {
                        borderColor: "#4ab3f4",
                        borderWidth: '1px'
                    }
                },
                // '& .MuiSelect': {
                //     padding: 0,
                //     backgroundColor: 'black'
                // }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: "#FFF",
                    backgroundColor: "#262626"
                },
                arrow: {
                    color: "#262626"
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        '&': {
                            // padding: '8px 0',
                            borderColor: '#4ab3f4',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#4ab3f4',
                        },
                    },

                },
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(127,146,160,0.15)"
                },
            }
        }
    },
    typography: {
        fontFamily: [
            'SF UI Text'
        ].join(','),
    },

    palette: {
        text: {
            primary: '#262626',
            secondary: '#7F92A0'
        },
        primary: {
            light: '#4ab3f4',
            main: '#1DA1F2',
            dark: '#1470a9',
            contrastText: '#fff',
        },
        secondary: {
            light: '#98a7b3',
            main: '#7F92A0',
            dark: '#586670',
            contrastText: '#ffffff',
        },
        error: {
            light: '#ff7b7f',
            main: '#FF5A5F',
            dark: '#b23e42',
            contrastText: '#fff',
        },
        warning: {
            light: '#fdab69',
            main: '#FD9644',
            dark: '#b1692f',
            contrastText: '#fff',
        },
        success: {
            light: '#55d5c7',
            main: '#2BCBBA',
            dark: '#1e8e82',
            contrastText: '#fff',
        },
        info: {
            light: '#8c8bdb',
            main: '#706FD3',
            dark: '#4e4d93',
            contrastText: '#fff',
        },
        disabledButton: {
            backgroundColor: '#7F92A0'
        },
        action: {
            disabledBackground: '#7F92A0',
            disabled: '#ffffff'
        }
        // inherit: {
        //     light: '#515151',
        //     main: '#262626',
        //     dark: '#1a1a1a',
        //     contrastText: '#262626',
        // }
    },
}
