import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {Avatar} from "@mui/material";
import {MyRow} from "./MyRow";

const CommentItem = () => {
    return (
        <Root>
            <Part>
                <Avatar
                    sx={{width: 32, height: 32}}
                />
            </Part>
            <Part>
                <MyRow style={{
                    gap: '5px',
                    alignItems: 'flex-end'
                }}>
                    <Name>
                        Ashlynn Lubin
                    </Name>
                    <DateSend>
                        4m
                    </DateSend>
                </MyRow>

                <Message>
                    If we program the program, we can get to the AI monitor through the online THX interface!
                </Message>
            </Part>
        </Root>
    );
};

export default observer(CommentItem);

const Root = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
`;

const Part = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: var(--black);
`;

const DateSend = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray);
`;

const Message = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--black);
  word-break: break-word;
`;
