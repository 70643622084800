import {getPages, routes_template} from "gears-react";
import {content} from "../../UI/templates/content";
import {error} from "../../UI/templates/error";
import {path} from "../routers";
import {account_routers} from "../account/account_routers";
import {session_routers} from "../session/session_routers";
import {stream_routers} from "../stream/stream_routers";

export const content_router = getPages([
    ['*', error],
    ['/', content],
    [`${path.stream}/*`, routes_template(stream_routers)],
    [`${path.account}/*`, routes_template(account_routers)],
    [`${path.session}/*`, routes_template(session_routers)],
])