import {observer} from "mobx-react";
import React from "react";
import {ITemplate} from "gears-react";
import SessionRole from "../../atoms/session/SessionRole";

const Body = observer(() => {
    return <SessionRole/>
});

const template: ITemplate = {
    body: <Body/>
}

export const room = [template];