import React from 'react';
import Loader from "./Loader";
import styled from "styled-components";
import {MAX_WIDTH_PAGE} from "../../utils/utils";

const PageLoader = () => {
    return (
        <Root>
            <Loader/>
        </Root>
    );
};

export default PageLoader;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 99999;

  @media(min-width: 1030px){
    max-width: ${MAX_WIDTH_PAGE}px;
    margin: 0 auto;
  }
  
`