import {ITemplate} from "gears-react";
import {observer} from "mobx-react";
import styled from "styled-components";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Context} from "../../../context";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import {Stop, TickSquare} from "iconsax-react";
import {MyTextArea} from "../../atoms/MyTextArea";
import {TQuestionType} from "../../../../QMS/models/@others/TQuestionType";
import Answers from "../../atoms/Answers";
import {IQuizAnswer} from "../../../../QMS/models/quiz_question_answer/params/IQuizAnswer";
import {Checkbox, FormControlLabel, Skeleton, TextField} from "@mui/material";
import {BigTextField} from "../../atoms/BigTextField";
import {BigButton} from "../../atoms/BigButton";
import ChangeQuestionArrows from "../../atoms/ChangeQuestionArrows";
import NotFoundPage from "../../atoms/NotFoundPage";
import QuestionFormSkeleton from "../../atoms/QuestionFormSkeleton";
import {HiddenText} from "../../atoms/HiddenText";

const Body = observer(() => {
    const {store} = useContext(Context);
    const location = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        store.quiz.details.loader.set(true)
        return () => store.quiz.details.loader.set(true)
    }, [])

    const [id, setId] = useState<number | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        let _id = Number(location.pathname.split('/')[2])
        setId(_id)
    }, [location.pathname]);

    useEffect(() => {
        reload()
    }, [id])

    const reload = () => {
        if (id !== null && !isNaN(Number(id))) {
            store.quiz.details.request({id}).then(r => {
                if (r.status === 404) {
                    setNotFound(true)
                }
            })
        }
    }

    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<IQuizAnswer[]>([
        {
            text: '',
            correct_answer: true,
        },
    ]);
    const [answers, setAnswers] = useState<IQuizAnswer[]>([
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        }
    ]);
    const [question_type, setQuestionType] = useState<TQuestionType>(1)

    //check_disabled
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (question_type === 1) {
            if (
                question.trim() !== ''
                && answers[0].text.trim() !== ''
                && answers[1].text.trim() !== ''
                && answers[2].text.trim() !== ''
                && answers[3].text.trim() !== ''
                && (answers[0].correct_answer || answers[1].correct_answer || answers[2].correct_answer || answers[3].correct_answer)
            ) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        } else if (question_type === 2) {

            if (question.trim() !== '' && answer[0].text.trim() !== '') {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }

    }, [
        question,
        question_type,
        answers,
        answer
    ])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (id !== null && !isNaN(id)) {
            store.quiz_question.create.request({id}, {
                text: question,
                add_answers: question_type === 1 ? answers : answer,
                type: question_type
            }).then(r => {
                console.log(r)
                if (r.status === 201) {
                    window.location.reload()
                    // reload()
                } else {
                    // setError(true)
                }

            })
        }

    };

    if (notFound) return <NotFoundPage/>

    return <Root>
        <MyRow>
            {
                store.quiz.details.loader.get() ?
                    <Skeleton variant="rounded" width={`30%`}
                              height={24}/>
                    : <>
                        <BackButton to={`${path.quiz}/${store.quiz.details.get().id}${path.questions}`}/>
                        <HiddenText>{store.quiz.details.get().name}</HiddenText>
                    </>
            }
        </MyRow>

        {
            id !== null && <ChangeQuestionArrows is_create quiz_id={id} question_id={NaN}/>
        }

        {
            store.quiz.details.loader.get() ?
                <QuestionFormSkeleton/>
                : <form onSubmit={handleSubmit}>
                    <MyTextArea

                        inputProps={{maxLength: 200}}
                        fullWidth
                        label="Вопрос"
                        multiline
                        rows={7}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        type={'text'}
                        InputProps={{
                            autoFocus: false,
                        }}
                        sx={{
                            margin: '16px 0'
                        }}
                    />


                    <Checker>
                        <FormControlLabel
                            sx={{
                                margin: 0,
                            }}
                            control={
                                <Checkbox
                                    onChange={() => {
                                        setQuestionType(question_type === 1 ? 2 : 1)
                                    }}
                                    defaultChecked
                                    sx={{
                                        padding: `0 8px 0 0`
                                    }} icon={<Stop
                                    size="24"
                                    color="#7F92A0"
                                />} checkedIcon={
                                    <TickSquare
                                        size="24"
                                        color="#1DA1F2"
                                    />
                                }/>
                            }
                            label={'4 вариата ответа'}
                        />
                    </Checker>

                    {
                        question_type === 1 ? <Answers answers={answers} setAnswers={setAnswers}/>
                            : question_type === 2 ? <div>
                                <BigTextField
                                    InputProps={{
                                        autoFocus: false,
                                        autoComplete: 'off',
                                    }}
                                    value={answer[0].text} onChange={(e) => {
                                    setAnswer([
                                        {
                                            text: e.target.value,
                                            correct_answer: true,
                                        },
                                    ])
                                }}
                                    fullWidth
                                    label={`Введите ответ`}
                                    variant="standard"/>
                            </div> : <></>
                    }

                    <BigButton
                        sx={{
                            margin: '48px 0 16px 0'
                        }}
                        fullWidth
                        disabled={disabled}
                        type={'submit'}
                        variant="contained"
                        color={'success'}
                        loading={store.quiz_question.create.loader.get()}
                    >
                        Еще вопрос
                    </BigButton>

                    {/*<BottomPos>*/}
                    {/*   */}
                    {/*</BottomPos>*/}

                </form>
        }
    </Root>
})

const template: ITemplate = {
    body: <Body/>
}

export const create_question = [template]

const Root = styled.div`
  padding: 16px 16px calc(20vh + 50px) 16px;
`

const Checker = styled.div`
  width: 100%;
  margin-bottom: 8px;
`