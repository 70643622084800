import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../context";
import PageLoader from "../PageLoader";
import NotFoundPage from "../NotFoundPage";

type Props = {
    children: React.ReactNode;
    session_id: number;
}

const SessionDetails = ({children,session_id}: Props) => {
    const {store} = useContext(Context);
    const [not_found, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (session_id !== null && !isNaN(session_id)) {
            setNotFound(false)
            store.quiz_session.details.request({id: session_id}).then(r => {
                console.log(r)
                if (r?.status === 200) {

                } else {
                    setNotFound(true)
                }
            })
        } else {
            store.quiz_session.details.loader.set(false)
            setNotFound(true)
        }
    }, [session_id])

    if (store.quiz_session.details.loader.get()) return <PageLoader/>

    if (not_found) return <NotFoundPage/>

    return <>{children}</>
};

export default observer(SessionDetails);