import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import {Divider, InputAdornment, Skeleton} from "@mui/material";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import {BigTextField} from "./BigTextField";
import {ArrowDown2} from "iconsax-react";

type Props = {
    value?: string;
    id?: number;

    mainValue?: string;
    setMainValue?: Dispatch<SetStateAction<string>>

    mainId?: number;
    setMainId?: Dispatch<SetStateAction<number>>

}

const CategoryInput = ({value, mainValue, id, setMainValue, mainId, setMainId}: Props) => {

    return (<div>
            {value !== undefined
            && mainValue !== undefined
            && id !== undefined
            && setMainValue !== undefined
            && mainId !== undefined
            && setMainId !== undefined
                ? <Root>
                    <BigTextField
                        fullWidth
                        variant="standard"
                        type={'text'}
                        value={value}
                        sx={{
                            ".MuiInputBase-input": {
                                cursor: 'pointer',
                            }
                        }}
                        onClick={() => {
                            if (mainId !== id) {
                                setMainValue(value)
                                setMainId(id)
                            } else {
                                setMainValue('')
                                setMainId(NaN)
                            }
                        }}
                        InputProps={{
                            autoComplete: 'off',
                            autoFocus: false,
                            readOnly: true,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CircleButton
                                    >
                                        {mainId === id ?
                                            <CircleTrue><Circle/></CircleTrue> :
                                            <CircleFalse></CircleFalse>}
                                    </CircleButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Divider sx={{
                        borderColor: '#7F92A0'
                    }}/>
                </Root> : <>
                    <Skeleton variant="rounded" width={'100%'} height={56} style={{
                        flexShrink: '0'
                    }}/>
                </>}
        </div>
    );
};

export default observer(CategoryInput);


const CircleButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
`
const CircleTrue = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #00C1C1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00C1C1;
`
const CircleFalse = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #7F92A0;
`


const Root = styled.div`
  cursor: pointer;
`