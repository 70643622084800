import {getPages, routes_template} from "gears-react";
import {error} from "../../UI/templates/error";
import {quiz_list} from "../../UI/templates/quiz/quiz_list";
import {path} from "../routers";
import {questions_routers} from "../questions/questions_routers";
import {create_quiz} from "../../UI/templates/quiz/create_quiz";
import {edit_quiz} from "../../UI/templates/quiz/edit_quiz";
import {history_quiz} from "../../UI/templates/quiz/history_quiz";

export const quiz_router = getPages([
    ['*', error],
    ['/', quiz_list],
    [`/:quiz_id${path.questions}/*`, routes_template(questions_routers)],
    [path.create, create_quiz],
    [`/:quiz_id`, edit_quiz],
    [path.history, history_quiz],
])