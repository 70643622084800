import {getPages, routes_template} from "gears-react";
import {error} from "../../UI/templates/error";
import {stream_list} from "../../UI/templates/stream/stream_list";
import {path} from "../routers";
import {stream_category_list} from "../../UI/templates/stream/stream_category_list";

export const stream_routers = getPages([
    ['*', error],
    ['/', stream_category_list],
    [path.list, stream_list],
])