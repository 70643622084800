import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {Next, Previous} from "iconsax-react";
import {path} from "../../routers/routers";
import {Context} from "../../context";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/material";

type Props = {
    is_create?: boolean;
    quiz_id: number;
    question_id: number;
}

const ChangeQuestionArrows = ({is_create, quiz_id, question_id}: Props) => {
    const {store} = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    // useEffect(() => {
    //     store.quiz_question.list.loader.set(true)
    //     return () => {
    //         store.quiz_question.list.loader.set(true)
    //     }
    // }, [])

    const [index, setIndex] = useState<number>(NaN);

    useEffect(() => {
        if (question_id !== null && !isNaN(question_id) && quiz_id !== null && !isNaN(quiz_id)) {
            store.quiz_question.details.request({id: quiz_id}, {id: question_id}).then(r => {
                switch (r.status) {
                    case 200:
                        setIndex(store.quiz_question.details.get().index + 1)
                        break;
                }
            })

        }
    }, [question_id])

    const [limitQN, setLimitQN] = useState<number>(100);
    const [next_QN, setNextQN] = useState<number>(NaN);
    const [prev_QN, setPrevQN] = useState<number>(NaN);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (quiz_id !== null && !isNaN(quiz_id)) {
                store.quiz_question.list.request({id: quiz_id}, {offset: 0, limit: limitQN}).then(r => {
                    switch (r.status) {
                        case 200:
                            if (is_create) {
                                setIndex(store.quiz_question.list.get().count + 1)
                                // console.log(store.quiz_question.list.get().results[store.quiz_question.list.get().results.length - 1].id)
                                setPrevQN(store.quiz_question.list.get().results[store.quiz_question.list.get().results.length - 1].id)
                                setNextQN(NaN)
                            } else {
                                let is_found = store.quiz_question.list.get().results.some((value, index, array) => {
                                    if (value.id === question_id) {
                                        if (array[index + 1] !== undefined) {
                                            setNextQN(array[index + 1].id)

                                            if (array[index - 1] !== undefined) {
                                                setPrevQN(array[index - 1].id)
                                            } else {
                                                setPrevQN(NaN)
                                            }

                                            return value.id === question_id
                                        } else {
                                            setNextQN(NaN)

                                            if (array[index - 1] !== undefined) {
                                                setPrevQN(array[index - 1].id)
                                            } else {
                                                setPrevQN(NaN)
                                            }

                                            return false
                                        }

                                    }
                                })

                                if (!is_found) {
                                    if (limitQN <= store.quiz_question.list.get().count) {
                                        setLimitQN(limitQN + 5)
                                    } else {
                                        setNextQN(NaN)
                                    }
                                }
                            }

                            break;
                    }
                })
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [quiz_id, question_id, limitQN])


    if (isNaN(index) || store.quiz.details.loader.get()) return <Arrows>
        <Skeleton variant="rounded" width={`152px`} height={24}/>
    </Arrows>

    return (
        <Arrows>
            {
                index > 1 && !isNaN(prev_QN) ? <Previous
                    size="24"
                    color="#7F92A0"
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        // prev_QN
                        if (!isNaN(prev_QN)) {
                            navigate(`${path.quiz}/${store.quiz.details.get().id}${path.questions}${path.edit}/${prev_QN}`)
                        }
                    }}
                /> : <div style={{
                    width: '24px'
                }}></div>
            }
            <Title>Вопрос {index}</Title>

            {
                index - 1 < store.quiz_question.list.get().count ? <Next
                    size="24"
                    color="#7F92A0"
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        // next_QN
                        if (isNaN(next_QN)) {
                            navigate(`${path.quiz}/${store.quiz.details.get().id}${path.questions}${path.create}`)
                        } else {
                            navigate(`${path.quiz}/${store.quiz.details.get().id}${path.questions}${path.edit}/${next_QN}`)
                        }
                    }}
                /> : <div style={{
                    width: '24px'
                }}></div>
            }

        </Arrows>
    );
};

export default observer(ChangeQuestionArrows);


const Arrows = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-top: 16px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--black);
`
