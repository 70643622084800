import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const MyTextField = styled(TextField)`

  .MuiInputBase-root {
    background-color: white;
    border: 1px solid var(--gray);
    border-radius: 8px;
    transition: all .3s;
  }

  .MuiInputBase-root:hover, .MuiInputBase-root:focus {
    background-color: white;
    border: 1px solid var(--main);
  }
`;


