import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import {Header} from "../../atoms/Header";
import {MyRowSpaceBetween} from "../../atoms/MyRow";
import logo_img from "../../assets/images/HeaderLogo.svg";
import {ArrowDown2} from "iconsax-react";
import * as React from "react";
import {Row} from "../session/session_list";
import NoData from "../../atoms/NoData";
import {useWindowSize} from "usehooks-ts";
import no_data_img from '../../assets/images/no_streams_img.svg'

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {width, height} = useWindowSize()


    return <div>
        <Header>
            <MyRowSpaceBetween>
                <Logo src={logo_img}/>
                <Row onClick={() => navigate('/')}>
                    <PageTitle>{t("content.link.streams.title")}</PageTitle>
                    <ArrowDown2
                        size="24"
                        color="#1DA1F2"
                    />
                </Row>
            </MyRowSpaceBetween>
        </Header>

        <Root>
            <NoData
                height={height}
                title={t("stream.list.nodata.title")}
                subtitle={t("stream.list.nodata.subtitle")}
                src={no_data_img}
                img_width={'100%'}
            />

            {/*<StreamCard/>*/}
        </Root>

    </div>
});

const template: ITemplate = {
    body: <Body/>
}

export const stream_list = [template];


const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  //padding: calc(16px + var(--header-height) * 2.144) 16px;
  padding: calc(var(--header-height) + 16px) 16px;

`

const Logo = styled.img`
  width: 68px;
`;

const PageTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--main);
`;