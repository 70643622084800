import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";

type State = {
    time: number;
    seconds: number;
    minutes: number;
}

type Props = {
    time: number;
    setTimer: Dispatch<SetStateAction<number>>
}

const Timer = ({time, setTimer}: Props) => {

    useEffect(() => {

        const timer = setTimeout(() => {
            if (time <= 0) {
                return;
            }

            setTimer(time - 1)

        }, 1000)
        return () => clearTimeout(timer);
    }, [time])

    return (
        <>
            {time >= 0 ? time : 0}
        </>
    );
};

export default observer(Timer);