export const quiz = {
    get_all() {
        return `/QMS/api/v1.0/public/quiz/`;
    },
    post() {
        return `/QMS/api/v1.0/public/quiz/`;
    },
    get(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    patch(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    delete(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/`;
    },
    new_session(id: number) {
        return `/QMS/api/v1.0/public/quiz/${id}/new_session/`;
    },
}

export const quiz_category = {
    get_all() {
        return `/QMS/api/v1.0/public/category/`;
    },
    get(id: number) {
        return `/QMS/api/v1.0/public/category/${id}/`;
    },
}

export const quiz_question = {
    get_all(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/`;
    },
    post(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/`;
    },
    get(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
    patch(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
    delete(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${id}/`;
    },
}

export const quiz_question_answer = {
    get_all(quiz_id: number, question_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/`;
    },
    post(quiz_id: number, question_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/`;
    },
    get(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
    patch(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
    delete(quiz_id: number, question_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/question/${question_id}/answer/${id}/`;
    },
}

export const quiz_session = {
    get_all() {
        return `/QMS/api/v1.0/public/session/`;
    },
    get(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/`;
    },
    close_registration(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/close_registration/`;
    },
    close(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/close_session/`;
    },
    connect(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/connect/`;
    },
    reconnect(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/reconnect/`;
    },
    update_participant_name(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/participant/`;
    },
    show_my_result(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/show_my_result/`;
    },
    show_question_result(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/show_question_result/`;
    },
    show_result(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/show_result/`;
    },
    is_creator(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/is_creator/`
    },
    participant(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/participant/`
    },
    calculate_places(id: number) {
        return `/QMS/api/v1.0/public/session/${id}/calculate_places/`
    }
}

export const quiz_session_sync = {
    answer(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/answer/`;
    },
    check_answers(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/check_answers/`;
    },
    check_timer(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/check_timer/`;
    },
    is_answered(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/is_answered/`;
    },
    next_question(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/next_question/`;
    },
    show_question(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/show_question/`;
    },
    start_timer(id: number) {
        return `/QMS/api/v1.0/public/session-sync/${id}/start_timer/`;
    },
}

export const quiz_session_async = {
    answer(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/answer/`;
    },
    check_timer(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/check_timer/`;
    },
    is_answered(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/is_answered/`;
    },
    next_question(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/next_question/`;
    },
    show_question(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/show_question/`;
    },
    start_timer(id: number) {
        return `/QMS/api/v1.0/public/session-async/${id}/start_timer/`;
    },
}

export const quiz_image = {
    get_all(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/`;
    },
    post(quiz_id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/`;
    },
    get(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    },
    patch(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    },
    delete(quiz_id: number, id: number) {
        return `/QMS/api/v1.0/public/quiz/${quiz_id}/image/${id}/`;
    },
}

