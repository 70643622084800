import {ITemplate} from "gears-react";
import {observer} from "mobx-react";
import styled from "styled-components";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Context} from "../../../context";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import {MyTextArea} from "../../atoms/MyTextArea";
import {TQuestionType} from "../../../../QMS/models/@others/TQuestionType";
import Answers from "../../atoms/Answers";
import {IQuizAnswer} from "../../../../QMS/models/quiz_question_answer/params/IQuizAnswer";
import {Skeleton} from "@mui/material";
import {BigTextField} from "../../atoms/BigTextField";
import {BigButton} from "../../atoms/BigButton";
import ChangeQuestionArrows from "../../atoms/ChangeQuestionArrows";
import NotFoundPage from "../../atoms/NotFoundPage";
import QuestionFormSkeleton from "../../atoms/QuestionFormSkeleton";
import {HiddenText} from "../../atoms/HiddenText";

const Body = observer(() => {
    const {store} = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        store.quiz.details.loader.set(true)
        store.quiz_question.details.loader.set(true)
        store.quiz_question.details.loader.set(true)

        return () => {
            store.quiz.details.loader.set(true)
            store.quiz_question.details.loader.set(true)
            store.quiz_question.details.loader.set(true)
        }
    }, [])

    const [error, setError] = useState<boolean>(false);

    const [quiz_id, setQuizId] = useState<number | null>(null);
    const [question_id, setQuestionId] = useState<number | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        let _quiz_id = Number(location.pathname.split('/')[2])

        if (_quiz_id !== null && !isNaN(Number(_quiz_id))) {
            setQuizId(_quiz_id)

            store.quiz.details.request({id: _quiz_id}).then(r => {
                if (r.status === 404) {
                    setNotFound(true)
                }
            })
        }

        let _question_id = Number(location.pathname.split('/')[5])

        if (_question_id !== null && !isNaN(Number(_question_id))) {
            setQuestionId(_question_id)

            store.quiz_question.details.request({id: _quiz_id}, {id: _question_id}).then(r => {
                if (r.status === 404) {
                    setNotFound(true)
                }
            })
        }

    }, [location.pathname]);


    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<IQuizAnswer[]>([
        {
            text: '',
            correct_answer: true,
        },
    ]);
    const [answers, setAnswers] = useState<IQuizAnswer[]>([
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        },
        {
            text: '',
            correct_answer: false,
        }
    ]);
    const [question_type, setQuestionType] = useState<TQuestionType>(1)


    useEffect(() => {
        if (question_id !== null && !isNaN(question_id) && quiz_id !== null && !isNaN(quiz_id)) {
            store.quiz_question.details.request({id: quiz_id}, {id: question_id}).then(r => {
                console.log(r)
                switch (r.status) {
                    case 200:
                        setQuestion(store.quiz_question.details.get().text)
                        if (store.quiz_question.details.get().type === 1) {
                            setAnswers(store.quiz_question.details.get().answers)
                        } else {
                            setAnswer(store.quiz_question.details.get().answers)
                        }

                        setQuestionType(store.quiz_question.details.get().type)
                        break;
                }
            })

        }
    }, [quiz_id, question_id])

    //check_disabled
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (question_type === 1) {
            if (
                question.trim() !== ''
                && answers[0].text.trim() !== ''
                && answers[1].text.trim() !== ''
                && answers[2].text.trim() !== ''
                && answers[3].text.trim() !== ''
                && (answers[0].correct_answer || answers[1].correct_answer || answers[2].correct_answer || answers[3].correct_answer)
            ) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        } else if (question_type === 2) {

            if (question.trim() !== '' && answer[0].text.trim() !== '') {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }

    }, [
        question,
        question_type,
        answers,
        answer
    ])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (question_id !== null && !isNaN(question_id) && quiz_id !== null && !isNaN(quiz_id)) {
            store.quiz_question.change.request({id: quiz_id}, {id: question_id}, {
                text: question,
                add_answers: question_type === 1 ? answers : answer,
                type: question_type
            }).then(r => {
                console.log(r)
                if (r.status === 200) {
                    setError(false)
                } else {
                    setError(true)
                }

            })
        }
    };

    const Delete = (e: any) => {
        e.preventDefault()
        if (question_id !== null && !isNaN(question_id) && quiz_id !== null && !isNaN(quiz_id)) {
            store.quiz_question.delete.request({id: quiz_id}, {id: question_id}).then(r => {
                if (r.status === 200) {
                    navigate(`${path.quiz}/${quiz_id}${path.questions}`)
                } else {
                    console.log('error')
                }
            })
        }
    }

    useEffect(() => {
        if (notFound) {
            navigate(`${path.quiz}/${quiz_id}${path.questions}`)
        }
    }, [notFound])

    if (notFound) return <NotFoundPage/>

    return <Root>
        <MyRow>
            {
                store.quiz.details.loader.get() ?
                    <Skeleton variant="rounded" width={`30%`}
                              height={24}/>
                    : <>
                        <BackButton to={`${path.quiz}/${store.quiz.details.get().id}${path.questions}`}/>
                        <HiddenText>{store.quiz.details.get().name}</HiddenText>
                    </>
            }
        </MyRow>
        {
            question_id !== null && quiz_id !== null &&
            <ChangeQuestionArrows question_id={question_id} quiz_id={quiz_id}/>
        }

        {
            store.quiz_question.details.loader.get()
            && store.quiz_question.details.loader.get()
                ? <QuestionFormSkeleton/>
                : <form onSubmit={handleSubmit}>
                    <MyTextArea
                        error={error}
                        inputProps={{maxLength: 200}}
                        fullWidth
                        label="Вопрос"
                        multiline
                        rows={7}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        type={'text'}
                        InputProps={{
                            autoFocus: false,
                        }}
                        sx={{
                            margin: '16px 0'
                        }}
                    />

                    {
                        question_type === 1 ? <Answers answers={answers} setAnswers={setAnswers}/>
                            : question_type === 2 ? <div>
                                <BigTextField
                                    error={error}
                                    value={answer[0].text} onChange={(e) => {
                                    setAnswer([
                                        {
                                            text: e.target.value,
                                            correct_answer: true,
                                        },
                                    ])
                                }}
                                    fullWidth
                                    label={`Введите ответ`}
                                    variant="standard"/>
                            </div> : <></>
                    }


                    <BigButton
                        sx={{
                            margin: '48px 0 16px 0'
                        }}
                        fullWidth
                        disabled={disabled}
                        type={'submit'}
                        variant="contained"
                        color={'primary'}
                        loading={store.quiz_question.change.loader.get()}
                    >
                        Сохранить
                    </BigButton>

                    <BigButton
                        fullWidth
                        onClick={Delete}
                        variant="contained"
                        color={'error'}
                        loading={store.quiz_question.delete.loader.get()}
                    >
                        Удалить
                    </BigButton>

                </form>
        }
    </Root>
})

const template: ITemplate = {
    body: <Body/>
}

export const edit_question = [template]

const Root = styled.div`
  padding: 16px 16px calc(20vh + 50px) 16px;

  @media (max-height: 730px) {
    padding: 16px 16px calc(30vh + 100px) 16px;
  }
`