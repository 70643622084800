import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {Account} from "../services/account";
import {IGetAccountInfo} from "../models/account/responses/IGetAccountInfo";

export class AccountStore {
    constructor() {
        makeAutoObservable(this);
    }

    private isAuth: boolean = false;

    public getIsAuth() {
        return this.isAuth;
    }

    public setIsAuth(isAuth: boolean) {
        this.isAuth = isAuth;
    }

    public readonly details = new ServiceStore<[], IGetAccountInfo>(Account.get, true);
}