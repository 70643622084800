import React from 'react';
import {Skeleton} from "@mui/material";
import styled from "styled-components";

const SkeletonForm = () => {
    return (
        <Root>
            <Skeleton variant="rounded" width={`100%`} height={53}/>
            <Skeleton variant="rounded" width={`100%`} height={53}/>
            <Skeleton variant="rounded" width={`100%`} height={53}/>
            <Skeleton variant="rounded" width={`100%`} height={53}/>
            <Skeleton variant="rounded" width={`100%`} height={53}/>
        </Root>
    );
};

export default SkeletonForm;


const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;