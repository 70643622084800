import {$qms} from "../http";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {AxiosResponse} from "axios";
import {IGetAll} from "../models/quiz_category/params/IGetAll";
import {IQuizCategoryRow} from "../models/quiz_category/responses/IQuizCategoryRow";
import {quiz_category} from "../endpoints/qms";
import {ID} from "../models/@others/ID";

export class QuizCategory {
    static async get_all({
                             limit, offset, ordering, search
                         }: IGetAll): Promise<AxiosResponse<IListDataResponse<IQuizCategoryRow>>> {
        return await $qms.get<IListDataResponse<IQuizCategoryRow>>(quiz_category.get_all(), {
            params: {
                limit, offset, ordering, search
            }
        })
    }

    static async get({id}: ID): Promise<AxiosResponse<IQuizCategoryRow>> {
        return await $qms.get<IQuizCategoryRow>(quiz_category.get(id))
    }

}