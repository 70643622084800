import {getPages, routes_template} from "gears-react";
import {error} from "../../UI/templates/error";
import {questions_list} from "../../UI/templates/questions/questions_list";
import {path} from "../routers";
import {create_question} from "../../UI/templates/questions/create_question";
import {edit_question} from "../../UI/templates/questions/edit_question";

export const questions_routers = getPages([
    ['*', error],
    ['/', questions_list],
    [path.create, create_question],
    [`${path.edit}/:question_id`, edit_question]

])