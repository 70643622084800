import {AxiosResponse} from "axios";
import {$qms} from "../http";
import {ID} from "../models/@others/ID";
import {ISendAnswer} from "../models/quiz_session_sync/params/ISendAnswer";
import {ITimerResponse} from "../models/quiz_session_sync/responses/ITimerResponse";
import {IShowQuestionResponse} from "../models/quiz_session_sync/responses/IShowQuestionResponse";
import {quiz_session_async} from "../endpoints/qms";

export class QuizSessionAsync {

    static async answer({id}: ID, {answer}: ISendAnswer): Promise<AxiosResponse> {
        return await $qms.post(quiz_session_async.answer(id), {
            answer
        })
    }

    static async check_timer({id}: ID): Promise<AxiosResponse<ITimerResponse>> {
        return await $qms.get<ITimerResponse>(quiz_session_async.check_timer(id))
    }

    static async is_answered({id}: ID): Promise<AxiosResponse> {
        return await $qms.get(quiz_session_async.is_answered(id))
    }

    static async next_question({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session_async.next_question(id))
    }

    static async show_question({id}: ID): Promise<AxiosResponse<IShowQuestionResponse>> {
        return await $qms.get<IShowQuestionResponse>(quiz_session_async.show_question(id))
    }

    static async start_timer({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session_async.start_timer(id))
    }
}