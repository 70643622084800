import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {QuizSession} from "../services/quiz_session";
import {IGetAll} from "../models/quiz_session/params/IGetAll";
import {IQuizSessionRow} from "../models/quiz_session/responses/IQuizSessionRow";
import {IGet} from "../models/quiz_session/params/IGet";
import {ID} from "../models/@others/ID";
import {IConnectParams} from "../models/quiz_session/params/IConnectParams";
import {IConnectResponse} from "../models/quiz_session/responses/IConnectResponse";
import {IShowResult} from "../models/quiz_question/params/IShowResult";
import {IResultResponse} from "../models/quiz_question/responses/IResultResponse";
import {IShowMyResultParams} from "../models/quiz_session/params/IShowMyResultParams";

export class QuizSessionStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly list = new ServiceStore<[IGetAll], IListDataResponse<IQuizSessionRow>>(QuizSession.get_all, true);
    public readonly details = new ServiceStore<[IGet], IQuizSessionRow>(QuizSession.get, true);
    public readonly close = new ServiceStore<[ID], any>(QuizSession.close, false);
    public readonly connect = new ServiceStore<[ID, IConnectParams], IConnectResponse>(QuizSession.connect, false);
    public readonly reconnect = new ServiceStore<[ID], IConnectResponse>(QuizSession.reconnect, true);
    public readonly close_registration = new ServiceStore<[ID], any>(QuizSession.close_registration, false);
    public readonly show_result = new ServiceStore<[ID, IShowResult], IListDataResponse<IResultResponse>>(QuizSession.show_result, true);
    public readonly show_my_result = new ServiceStore<[IShowMyResultParams], IResultResponse>(QuizSession.show_my_result, false);
    public readonly is_creator = new ServiceStore<[ID], any>(QuizSession.is_creator, true);
    public readonly participant = new ServiceStore<[ID], any>(QuizSession.participant, true);
    public readonly calculate_places = new ServiceStore<[ID], any>(QuizSession.calculate_places, true);


}