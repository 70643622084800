import React, {useContext, useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {ILang, useLocalStorage} from "gears-react";
import {Container, FormControl, InputLabel, Select} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ArrowDown2} from "iconsax-react";
import styled from "styled-components";
import {Context} from "../../context";

type Props = {
    color?: string;
}

const TranslationButton = ({color}: Props) => {
    const [language, setLanguage] = useLocalStorage('language', 'en');
    const {store} = useContext(Context);
    const {t} = useTranslation();

    return (
        <div>

            <Select
                IconComponent={(props) => (
                    <ArrowDown2
                        {...props}
                        size="18"
                        color="#fff"
                    />
                )}
                MenuProps={{}}
                SelectDisplayProps={{}}
                variant={'outlined'}
                sx={{
                    color: 'white',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0}
                }}
                value={language}
                onChange={(e) => {
                    setLanguage(e.target.value);
                    store.lang.set(e.target.value)
                }}
            >
                {store.lang.getAll().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default observer(TranslationButton);

const MySelect = styled.div`
  display: flex;
  color: white;
  gap: 10px;
  cursor: pointer;
`