import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../context";
import {useLocation} from "react-router-dom";
import NotFoundPage from "../NotFoundPage";
import PageLoader from "../PageLoader";
import Creator from "./creator/Creator";
import Client from "./client/Client";
import SessionDetails from "./SessionDetails";

const SessionRole = () => {
    const {store} = useContext(Context);
    const location = useLocation();

    const [is_creator, setIsCreator] = useState<boolean>(false);
    const [session_id, setSessionId] = useState<number | null>(null);
    const [not_found, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        let _id = location.pathname.split('/')
        setSessionId(Number(_id[2]))
    }, [location.pathname]);

    useEffect(() => {
        store.quiz_session.is_creator.loader.set(true)
        return () => {
            store.quiz_session.is_creator.loader.set(true)
        }
    }, [])

    useEffect(() => {
        if (session_id !== null && !isNaN(session_id)) {
            setNotFound(false)
            store.quiz_session.is_creator.request({id: session_id}).then(r => {
                if (r?.status === 200) {
                    setIsCreator(true)
                } else if (r?.status === 400) {
                    setIsCreator(false)
                } else {
                    setNotFound(true)
                }
            })
        } else {
            store.quiz_session.is_creator.loader.set(false)
            setNotFound(true)
        }
    }, [session_id])

    if (store.quiz_session.is_creator.loader.get()) return <PageLoader/>

    if (not_found) return <NotFoundPage/>

    return (is_creator ?
            <SessionDetails session_id={session_id!}>
                <Creator/>
            </SessionDetails>
            :
            <SessionDetails session_id={session_id!}>
                <Client/>
            </SessionDetails>
    )
};

export default observer(SessionRole);