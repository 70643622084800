import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {IconButton} from "@mui/material";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import styled from "styled-components";
import filter_img from '../assets/images/filter.svg'
import filter_active_img from '../assets/images/filter_active.svg'
import {useTranslation} from "react-i18next";


type Props = {
    children?: React.ReactNode,
    active: boolean;
}

const FilterDrawer = ({children, active}: Props) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <div>
            <IconButton onClick={() => setOpen(true)}>
                {active ? <Icon src={filter_active_img}/> : <Icon src={filter_img}/>}
            </IconButton>

            <SwipeableEdgeDrawer open={open} setOpen={setOpen} label={t("filters")}>
                <Root>
                    {children}
                </Root>
            </SwipeableEdgeDrawer>
        </div>
    );
};

export default observer(FilterDrawer);

const Root = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
  padding: 24px 16px 100px 16px;
`;

const Icon = styled.img`
  width: 24px;
`;