import React, {useContext, useEffect, useState,} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../context";
import {BottomPos} from "../../BottomPos";
import styled from "styled-components";
import CloseSessionButton from "./CloseSessionButton";
import {Header} from "../../Header";
import {MyRow} from "../../MyRow";
import BackButton from "../../BackButton";
import {path} from "../../../../routers/routers";
import {Divider} from "@mui/material";
import PopupButton from "../../PopupButton";
import {BigButton} from "../../BigButton";
import {PopupContainer, PopupContainerTitle} from "../../PopupComponents";
import {useNavigate} from "react-router-dom";
import PageLoader from "../../PageLoader";

const CreatorShowResult = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        store.quiz_session.show_result.request({id: store.quiz_session.details.get().id}, {
            i_part: true,
            limit: 100,
            offset: 0,
        }).then(r => {
            console.log(r)
        })
    }, [])
    const [open, setOpen] = useState(false);

    if (store.quiz_session.show_result.loader.get()) return <PageLoader/>

    return (
        <>
            <Header>
                <MyRow>
                    <BackButton to={path.session}/>
                    <p>{store.quiz_session.details.get().quiz.name}</p>
                </MyRow>
            </Header>
            <Root>
                <div>
                    {store.quiz_session.show_result.get().results.length <= 0 && <div>
                        Нет результатов
                    </div>}

                    {store.quiz_session.show_result.get().results.map(e =>
                        <div key={e.id}>
                            {e.place === 1 && <p style={{
                                color: '#2BCBBA',
                                marginBottom: '8px'
                            }}>
                                Победитель
                            </p>}
                            <p style={{
                                marginBottom: '8px'
                            }}>Участник: <span>{e.name}</span></p>
                            <p style={{
                                marginBottom: '8px'
                            }}>Место: <span>{e.place}</span></p>
                            <p style={{
                                marginBottom: '8px'
                            }}>Правильных ответов: <span>{e.right_answer}</span></p>
                            <p>Время: <span>{e.sum_answer_time || 0} секунд</span>
                            </p>
                            <Divider style={{
                                margin: '16px 0'
                            }}/>
                        </div>)}

                </div>

                {<BottomPos>
                    <PopupButton title={'Завершить'} variant={'contained'} setOpen={setOpen} open={open}
                                 color={"error"}>
                        <PopupContainer>
                            <PopupContainerTitle>Завершить ?</PopupContainerTitle>
                            <BigButton
                                onClick={() => {
                                    store.quiz_session.close.request({id: store.quiz_session.details.get().id}).then(r => {
                                        // console.log(r)
                                        if (r.status === 200) {
                                            navigate(`/`)
                                        }
                                    })
                                }}
                                fullWidth
                                type={'submit'}
                                variant="contained"
                                color={'error'}
                                loading={store.quiz_session.close.loader.get()}
                            >
                                Да
                            </BigButton>
                            <BigButton
                                fullWidth
                                variant="outlined"
                                color={'primary'}
                                onClick={() => setOpen(false)}
                            >
                                Нет
                            </BigButton>
                        </PopupContainer>

                    </PopupButton>
                </BottomPos>}

            </Root>

        </>
    );
};

export default observer(CreatorShowResult);

const Root = styled.div`
  width: 100%;
  padding: calc(16px + var(--header-height)) 16px calc(20vh + 50px) 16px;

  @media (max-height: 730px) {
    padding: calc(16px + var(--header-height)) 16px calc(20vh + 50px) 16px;
`;
