import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {BigButton} from "./BigButton";
import ads_img_demo from '../assets/images/ads_gena.jpg';

const Ads = () => {
    return (
        <Root>

            <BigButton variant={'outlined'} color={'primary'} fullWidth>Для рекламы: (99) 820-48-28</BigButton>

            <Ad style={{
                backgroundImage: `url(${ads_img_demo})`
            }}>
                {/*Место для вашей рекламы*/}
            </Ad>
        </Root>
    );
};

export default observer(Ads);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const Ad = styled.div`
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vw;
  max-height: 300px;
  background-color: var(--main);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  text-align: center;
`;
