import * as React from 'react';
import {Global} from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Dispatch, SetStateAction, useEffect} from "react";
import styled from "@emotion/styled";
import {MAX_WIDTH_PAGE} from "../../utils/utils";

const drawerBleeding = 0;

interface Props {
    open: boolean;
    children: React.ReactNode;
    setOpen: Dispatch<SetStateAction<boolean>>;
    label?: string;
}


const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: '#fff',
}));

const Puller = styled(Box)(({theme}) => ({
    width: 32,
    height: 2,
    backgroundColor: 'var(--gray)',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: '0.4',
}));

export default function SwipeableEdgeDrawer({open, children, setOpen, label}: Props) {
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = document.body

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'unset';
        }
    }, [open])

    return (
        <Root>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <MySwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >

                {open && <>
                    <StyledBox
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                        }}
                    >
                        <Puller/>
                        {label !== undefined && <Typography
                            sx={{
                                padding: '24px 0',
                                color: 'text.primary',
                                textAlign: 'center',
                                fontWeight: '500',
                                fontSize: '18px'
                            }}>{label}</Typography>}

                    </StyledBox>
                    <StyledBox
                        sx={{
                            position: 'relative',
                            marginTop: '72px',
                            height: '100%',
                            overflowY: 'scroll',
                        }}
                    >
                        {children}
                    </StyledBox>

                </>}

            </MySwipeableDrawer>
        </Root>
    );
}


const Root = styled.div`
{
  @media (min-width: 1030px) {
    max-width: 436px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const MySwipeableDrawer = styled(SwipeableDrawer)`

  @media (min-width: 1030px) {
    max-width: ${MAX_WIDTH_PAGE}px;
    left: 50%;
    transform: translateX(-50%);
  }

  .MuiDrawer-paper {
    border-radius: 25px 25px 0 0 !important;
    overflow-x: hidden
  }
    
`;