import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from "styled-components";
import {Gallery, Camera, CloseCircle} from "iconsax-react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {Image} from "iconsax-react";

type Props = {
    preview_link?: string;
    image: any,
    setImage: Dispatch<SetStateAction<any>>;
}

const ImageUpload = ({image, setImage, preview_link}: Props) => {
    const {t} = useTranslation();
    const [preview, setPreview] = useState<any>('')

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!image) {
            setPreview('')
            return
        }
        const objectUrl = URL.createObjectURL(image)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [image])

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage(null)
            return
        }
        console.log(e.target.files[0])
        setImage(e.target.files[0])

        console.log(e.target.files)
        // setImages(e.target.files)

    }

    useEffect(() => {
        setPreview(preview_link)
    }, [preview_link])

    return (
        <Root>
            <Dropzone>

                <Image
                    size="24"
                    color="#7F92A0"
                    variant="Outline"
                />

                <Text variant={'body2'} color={'text.secondary'}>Добавьте фото</Text>
                <Preview style={{
                    backgroundImage: `url(${preview})`
                }}/>
                <Black>
                    <Camera
                        size="38"
                        color="#fff"
                    />
                </Black>
                <FileInput type={'file'} multiple accept="image/png, image/jpeg" onChange={onSelectFile}/>


            </Dropzone>

            {
                image !== null && <CancelButton onClick={() => {
                    setImage(null)
                    setPreview('')
                }}>
                    <CloseCircle
                        size="32"
                        color="#FF5A5F"
                        variant="Bold"
                    />
                </CancelButton>
            }

        </Root>
    );
};

export default ImageUpload;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 125px;
`;


const Black = styled.div`
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all .3s;
`;

const Dropzone = styled.div`
  position: relative;
  overflow: hidden;

  &:hover ${Black} {
    opacity: 0.7;
  }

  width: 100%;
  height: 125px;
  border: 0.5px dashed #7F92A0;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
`;

const Text = styled(Typography)`
  text-align: center;
  font-size: 12px !important;

  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;

  font-weight: 300;
  color: var(--gray);
`;


const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

`;
const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 125px;
  opacity: 0;
  cursor: pointer;
`;

const CancelButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  opacity: 1;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    flex-shrink: 0;
  }

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;


