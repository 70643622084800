import {getPages} from "gears-react";
import {error} from "../../UI/templates/error";
import {room} from "../../UI/templates/room/room";
import {path} from "../routers";
// import {creator_room_history} from "../../UI/templates/room/history/creator_room_history";
// import {client_room_history} from "../../UI/templates/room/history/client_room_history";

export const room_routers = getPages([
    ['*', error],
    [`/:id`, room],
    // [`/:id${path.history}${path.creator}`, creator_room_history],
    // [`/:id${path.history}${path.client}`, client_room_history],
])