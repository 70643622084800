import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import * as React from "react";
import {useWindowSize} from "usehooks-ts";
import {Header} from "../../atoms/Header";
import {MyRow} from "../../atoms/MyRow";
import BackButton from "../../atoms/BackButton";
import {path} from "../../../routers/routers";
import {HiddenText} from "../../atoms/HiddenText";
import RowCard from "../../atoms/RowCard";
import Scroll from "../../atoms/Scroll";
import NoData from "../../atoms/NoData";
import g8 from "../../assets/images/g8.svg";
import {IQuizSessionRow} from "../../../../QMS/models/quiz_session/responses/IQuizSessionRow";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {height} = useWindowSize()

    useEffect(() => {
        store.quiz_session.list.loader.set(true)
        return () => store.quiz_session.list.loader.set(true)
    }, [])

    const [againLoader, setAgainLoader] = useState<boolean>(true);

    const [limit, setLimit] = useState<number>(5);
    const [offset, setOffset] = useState<number>(0);

    const array = [0, 0, 0]

    const [list, setList] = useState<IQuizSessionRow[]>([]);

    useEffect(() => {
        reload()
    }, [limit, offset])

    const reload = () => {
        store.quiz_session.list.request({
            limit,
            search: '',
            offset,
            i_org: true,
            history: true,
        }).then(r => {
            console.log(r)
            if (store.quiz_session.list.get().results.length > 0) {
                setList([...list, ...store.quiz_session.list.get().results])
            }
        })
    }

    return <div>
        <Header>
            <MyRow>
                <BackButton to={path.quiz}/>
                <HiddenText>История ваших Квизов</HiddenText>
            </MyRow>
            {/*<FilterDrawer/>*/}
        </Header>

        <Root>
            {
                store.quiz_session.list.loader.get() && againLoader ?
                    array.map((e, i) => <RowCard
                        key={i}/>)
                    :
                    store.quiz_session.list.get().count > 0 ? <Scroll
                        offset={offset} setOffset={setOffset}
                        length={store.quiz_session.list.get().count}
                        limit={limit} setAgainLoader={setAgainLoader}
                    >
                        {
                            list.map((e, i) =>
                                <RowCard key={e.id}
                                         title={e.quiz.name}
                                         subtitles={[
                                             {body: 'id: ', main: `${e.id}`}
                                         ]}
                                         onClick={() => {
                                             // navigate(`${path.room}/${e.id}${path.history}${path.creator}`)
                                         }}
                                />
                            )
                        }
                    </Scroll> : <NoData
                        height={height}
                        title={'No session found'}
                        subtitle={'Click to reload list'}
                        src={g8}
                        onClick={() => {
                            reload()
                        }}
                        button_title={'Reload'}
                        img_width={'300px'}
                    />
            }
        </Root>
    </div>
});


const template: ITemplate = {
    body: <Body/>
}

export const history_quiz = [template];

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: calc(16px + var(--header-height)) 16px calc(20vh + 50px) 16px;
`