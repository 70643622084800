import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const BigTextField = styled(TextField)`

  .MuiInputBase-root {
    padding: 8px 0
  }

`;
