import {observer} from "mobx-react";
import styled from "styled-components";
import {ITemplate, RenderRoutes} from "gears-react";
import React, {useContext, useEffect} from "react";
import {useWindowSize} from "usehooks-ts";
import {main_routers} from "../../routers/main_routers";
import PageLoader from "../atoms/PageLoader";
import {Context} from "../../context";
import {auth_routers} from "../../routers/auth/auth_routers";
import {useNavigatorOnline} from "@oieduardorabelo/use-navigator-online";
import NoInternetConnection from "../atoms/NoInternetConnection";
import {MAX_WIDTH_PAGE} from "../../utils/utils";
import TranslationButton from "../atoms/TranslationButton";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";

const Body = observer(() => {
    const {store} = useContext(Context);
    const {width, height} = useWindowSize()
    let {isOnline} = useNavigatorOnline();
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location.pathname]);

    useEffect(() => {
        store.account.details.request().then(r => {
            if (r.status !== 200) {
                store.account.setIsAuth(false);
            } else {
                store.account.setIsAuth(true);
            }
        })
    }, [store.fast_auth.auth.get().access])

    if (store.account.details.loader.get() && isOnline) return <PageLoader/>

    return <>
        <LeftPos>
            <TranslationButton color={"white"}/>
        </LeftPos>

        <Root height={height} width={width}>


            {isOnline ?
                store.account.getIsAuth() ? <RenderRoutes routers={main_routers}/> :
                    <RenderRoutes routers={auth_routers}/>
                : <NoInternetConnection/>}
        </Root>
    </>

});

const template: ITemplate = {
    body: <Body/>
}

export const main = [template];

type RootProps = {
    height: number;
    width: number;
}

const Root = styled.div`
  min-height: ${({height}: RootProps) => height}px;
  background-color: white;
  position: relative;

  @media (min-width: 1030px) {
    max-width: ${MAX_WIDTH_PAGE}px;
    margin: 0 auto;
    box-shadow: 0px 2px 10px rgba(127, 146, 160, 0.15);
  }
`;


const LeftPos = styled.div`
  position: fixed;
  right: 16px;
  top: 16px;
`;
