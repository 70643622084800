import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {Divider, IconButton} from "@mui/material";
import {ArrowRight2} from "iconsax-react";

type Props = {
    title: string,
    subtitle: string,
    onClick: () => void,
}

const AccountNavigateButton = ({subtitle, title, onClick}: Props) => {
    return (<div>
            <Root onClick={() => onClick()}>
                <Titles>
                    <Title1>
                        {title}
                    </Title1>
                    <Title2>
                        {subtitle}
                    </Title2>
                </Titles>

                <IconButton>
                    <ArrowRight2
                        size="24"
                        color={'black'}
                    />
                </IconButton>

            </Root>
            <Divider/>
        </div>
    );
};

export default observer(AccountNavigateButton);

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 6px;
`;

const Titles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title1 = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
`;

const Title2 = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: var(--black);
  max-width: 266px;
`;