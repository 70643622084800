import {TextField} from "@mui/material";
import styled from "@emotion/styled";

export const SendTextField = styled(TextField)`

  .MuiInputBase-root {
    transition: all .3s;

    background: #F8F8F8;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    *{
      font-size: 14px;
    }


    .MuiInputBase-input {
      padding-bottom: 8px !important;
      padding-left: 6px !important;
      padding-top: 8px !important;
    }
  }

  .MuiInputBase-root:hover, .MuiInputBase-root:focus {
    background-color: white;
    border: 1px solid var(--main);
  }
`;
