import React, {Dispatch, SetStateAction, useState} from 'react';
import Popup from "./Popup";
import Button from "@mui/material/Button";
import {Clipboard} from "iconsax-react";
import {observer} from "mobx-react-lite";
import {BigButton} from "./BigButton";

interface PopupButtonProps {
    title: React.ReactNode;
    children: React.ReactNode;
    style?: any;
    variant?: "text" | "contained" | "outlined" | undefined
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean
}

const PopupButton = ({title, children, style, variant, color, open, setOpen}: PopupButtonProps) => {
    // const [open2, setOpen2] = useState(false);

    return (
        <>  <BigButton fullWidth style={style} color={color ? color : "primary"}
                       variant={variant ? variant : "contained"} sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
        }} onClick={() => {
            setOpen(true)
        }}>
            {title}
        </BigButton>
            <Popup open={open}
                   onClose={() => setOpen(false)}>
                {children}
            </Popup>
        </>
    );
};

export default observer(PopupButton);