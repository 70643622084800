import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import QuizCategoryDrawer from "./QuizCategoryDrawer";
import {BigTextField} from "./BigTextField";
import {MyTextArea} from "./MyTextArea";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ImageUpload from "./ImageUpload";

type Props = {
    mainId: number;
    setMainId: Dispatch<SetStateAction<number>>;
    getError: (field: string) => boolean;
    mainValue: string;
    setMainValue: Dispatch<SetStateAction<string>>;
    name: string;
    setName: Dispatch<SetStateAction<string>>;
    question_time: string;
    setQuestion_time: Dispatch<SetStateAction<string>>;
    description: string;
    setDescription: Dispatch<SetStateAction<string>>;
    reward: string;
    setReward: Dispatch<SetStateAction<string>>;
    image: any;
    setImage: Dispatch<SetStateAction<any>>;
}

const QuizFormInput = ({
                           setMainId,
                           mainId,
                           getError,
                           setMainValue,
                           mainValue,
                           setName,
                           name,
                           question_time,
                           setQuestion_time,
                           reward,
                           setReward,
                           setDescription,
                           description,
                           setImage,
                           image
                       }: Props) => {

    const {t} = useTranslation();

    return (
        <Inputs>
            <ImageUpload image={image} setImage={setImage}/>

            <QuizCategoryDrawer
                getError={getError}
                mainId={mainId}
                setMainId={setMainId}
                mainValue={mainValue}
                setMainValue={setMainValue}
            />

            <BigTextField
                error={getError('name')}
                required
                inputProps={{maxLength: 60}}
                fullWidth
                label={t("quiz.input.name")}
                variant="standard"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={'text'}
                InputProps={{
                    autoFocus: false,
                    autoComplete: 'off',

                }}
                sx={{
                    marginTop: '16px'
                }}
            />


            <BigTextField
                error={getError('question_time')}
                required
                fullWidth
                label={t("quiz.input.question.time")}
                variant="standard"
                InputProps={{
                    autoFocus: false,
                    autoComplete: 'off',
                }}
                value={question_time}
                type={'tel'}
                sx={{
                    marginTop: '16px'
                }}
                onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                        if (Number(e.target.value) <= 300) {
                            setQuestion_time(e.target.value)
                            if (e.target.value !== "") {
                                if (Number(e.target.value) <= 0) {
                                    setQuestion_time("1")
                                }
                            }

                        } else {
                            setQuestion_time("300")
                        }


                    }
                }}
            />

            <MyTextArea
                error={getError('description')}
                inputProps={{maxLength: 200}}
                fullWidth
                label={t("quiz.input.description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                type={'text'}
                InputProps={{
                    autoFocus: false,
                    autoComplete: 'off',
                }}
                sx={{
                    marginTop: '24px'
                }}
            />

            <BigTextField
                fullWidth
                inputProps={{maxLength: 60}}
                label={t("quiz.input.prize")}
                variant="standard"
                type={'text'}
                sx={{
                    marginTop: '16px'
                }}
                value={reward}
                onChange={(e) => setReward(e.target.value)}
                InputProps={{
                    autoComplete: 'off',
                    autoFocus: false,
                }}
            />
        </Inputs>
    );
};

export default observer(QuizFormInput);

const Inputs = styled.div`
  width: 100%;
`;
