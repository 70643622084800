import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Divider, IconButton, Skeleton, Tooltip} from "@mui/material";
import {Edit, ShoppingCart} from "iconsax-react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useProgressiveImage} from "../../hooks/useProgressiveImage";

type subtitle = {
    main: string;
    body: string;
}

type Props = {
    title?: string;
    img?: string;
    onClick?: (...args: any) => void;
    action?: () => void;
    subtitles?: subtitle[]
    ticker?: boolean;
}

const RowCard = ({img, title, onClick, action, subtitles, ticker}: Props) => {
    const {t} = useTranslation();

    const loaded = useProgressiveImage(img || '')

    return (
        <Root onClick={onClick!} style={{
            backgroundColor: `${title ? 'white' : 'none'}`,
            // boxShadow: `${title ? 'rgba(127, 146, 160, 0.15) 0px 7px 29px 0px' : 'none'}`
        }}>

            {
                img ? loaded ? <Image style={{
                    backgroundImage: `url(${loaded})`
                }}/> : <Skeleton variant="rounded" width={56} height={56} style={{
                    flexShrink: '0'
                }}/> : <></>
            }
            {title ?
                <Block>
                    <Row>
                        <Content>
                            <Tooltip title={title}>
                                <Name>
                                    {title}
                                </Name>
                            </Tooltip>

                            {
                                ticker ? <Ticker>
                                    <TickerItems>
                                        {subtitles !== undefined && subtitles.map((subtitle, index) => <TickerItem
                                            key={index}>
                                            {subtitle.main !== 'null' &&
                                                <span>{subtitle.body} {subtitle.main}</span>
                                            }
                                        </TickerItem>)}
                                    </TickerItems>

                                </Ticker> : <>
                                    <Subtitles>
                                        {subtitles !== undefined && subtitles.map((subtitle, index) => <Subtitle
                                            key={index}>
                                            {subtitle.main !== 'null' && <Tooltip title={subtitle.main}>
                                                <SubtitleKey>{subtitle.body} {subtitle.main}</SubtitleKey>
                                            </Tooltip>}
                                        </Subtitle>)}
                                    </Subtitles>
                                </>
                            }

                        </Content>

                        {action && <IconButton
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                action()
                            }}
                        >
                            <MoreVertIcon sx={{
                                fontSize: '24px',
                                color: '#7F92A0'
                            }}/>
                        </IconButton>}
                    </Row>
                    <Divider sx={{marginTop: '16px'}}/>
                </Block>
                : <Skeleton variant="rounded" width={`100%`}
                            height={subtitles !== undefined ? 16 : 56}/>}

        </Root>
    );
};

export default observer(RowCard);

export const Root = styled.div`
  cursor: pointer;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Block = styled.div`
  width: 100%;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
`;

export const Image = styled.div`
  width: 56px;
  height: 56px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
`;

export const Name = styled.div`
  user-select: none;
  -webkit-user-select: none;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #262626;
  white-space: nowrap;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;


export const Ticker = styled.div`
  color: #7F92A0;
  width:300px;
  height: 14px;
  //background-color: #1da1f2;
  overflow: hidden;
  position: relative;
`;

export const TickerItem = styled.span`
  color: #7F92A0;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 8px;
`;

export const TickerItems = styled.span`
  color: #7F92A0;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 8px;
  animation: text 5s infinite linear;
  position: absolute;
  @keyframes text {
    0%{
      transform: translate(160%, 0);
    }

    100%{
      transform: translate(-160%, 0);
    }
  }
  
`;


export const Subtitles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  //width: calc(100% - 56px - 16px);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`

export const SubtitleKey = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #7F92A0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
