import styled from "styled-components";
import {MAX_WIDTH_PAGE} from "../../utils/utils";

export const BottomPos = styled.div`
  width: 100%;
  position: fixed;
  bottom: 16px;
  left: 0;
  padding: 0 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media(min-width: 1030px){
    max-width: ${MAX_WIDTH_PAGE}px;
    left: 50%;
    transform: translateX(-50%);
  }
`
