import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Copied} from "../../Copied";
import styled from "styled-components";

type Props = {
    code: string;
}

const Code = ({code}:Props) => {
    const [is_copied, setIsCopied] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsCopied(false)
        }, 1500)
        return () => clearTimeout(timer);
    }, [is_copied])
    return (
        <div>
            <Root onClick={() => {
                navigator.clipboard.writeText(code)
                setIsCopied(true)
            }}>
                <CodeTitle>Код комнaты: {code}
                </CodeTitle>

                {
                    is_copied && <Copied>Код скопирован!</Copied>
                }
            </Root>
        </div>
    );
};

export default observer(Code);

const Root = styled.div`
  width: 100%;
  max-width: 213px;
  height: 56px;
  margin: 16px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px;
  font-size: 24px;
  flex-direction: column;
  font-weight: 500;
  border: 2px dashed #1DA1F2;
  border-radius: 6px;
  gap: 12px;
  cursor: pointer;

  transition: all .3s;

  &:hover {
    background-color: rgba(29, 161, 242, 0.1);
  }
`

const CodeTitle = styled.div`
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--main);
`