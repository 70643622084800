import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();


    return <Root>
        stream_category_list
    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const stream_category_list = [template];

const Root = styled.div`

`;
