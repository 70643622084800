import styled from "styled-components";

export const PopupContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden;
`

export const PopupContainerTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--black);
  width: 300px;
`