import React from 'react';
import {BigButton} from "../../BigButton";
import {path} from "../../../../routers/routers";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const FinishSession = () => {
    const navigate = useNavigate();

    return (
        <Root>
            <FinishSessionTitle>
                Викторина закончилась
            </FinishSessionTitle>
            <FinishSessionSubtitle>
                Не расстраивайтесь если не успели поучавствовать или проиграли. Вернитесь на главное
                меню и испытывайте себя в других викторинах.
            </FinishSessionSubtitle>

            <BigButton
                onClick={() => {
                    navigate(path.session)
                }}
                fullWidth
                variant="contained"
            >
                Другие квизы
            </BigButton>
        </Root>
    );
};

export default observer(FinishSession);


const Root = styled.div`
  padding: 16px;
`;

const FinishSessionTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #262626;
`;

const FinishSessionSubtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #1DA1F2;
  margin: 48px 0 24px 0;
`;