import React from 'react';
import {useNavigate} from "react-router-dom";
import NoData from "./NoData";
import no_found_img from "../assets/images/notFoundImage.svg";
import styled from "styled-components";
import {useWindowSize} from "usehooks-ts";
import {useTranslation} from "react-i18next";

type Props = {
    back_link?: string
}

const NotFoundPage = ({back_link}: Props) => {
    const {height} = useWindowSize()
    const navigate = useNavigate();
    const {t} = useTranslation();
    // {'Go to main page'}
    // {'Something gone wrong'}
    //

    return (
        <Root>
            <NoData
                height={height}
                title={t("404.title")}
                subtitle={t("404.subtitle")}
                src={no_found_img}
                onClick={() => navigate(back_link || '/')}
                button_title={t("404.button.title")}
                img_width={'300px'}
            />
        </Root>
    );
};

export default NotFoundPage;

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: calc(16px + var(--header-height)) 16px;
`
