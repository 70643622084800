import {AxiosResponse} from "axios";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {$qms} from "../http";
import {ID} from "../models/@others/ID";
import {IGetAll} from "../models/quiz_question/params/IGetAll";
import {IQuizQuestionRow} from "../models/quiz_question/responses/IQuizQuestionRow";
import {quiz_question} from "../endpoints/qms";
import {IPost} from "../models/quiz_question/params/IPost";
import {IResultResponse} from "../models/quiz_question/responses/IResultResponse";
import {IShowResult} from "../models/quiz_question/params/IShowResult";

export class QuizQuestion {
    static async get_all({id: quiz_id}: ID, {
        limit,
        offset,
        ordering,
        search,
        type
    }: IGetAll): Promise<AxiosResponse<IListDataResponse<IQuizQuestionRow>>> {
        return await $qms.get<IListDataResponse<IQuizQuestionRow>>(quiz_question.get_all(quiz_id), {
            params: {
                limit,
                offset,
                ordering,
                search,
                type
            }
        })
    }

    static async post({id}: ID, {
        text,
        type,
        add_answers
    }: IPost): Promise<AxiosResponse<IQuizQuestionRow>> {
        return await $qms.post<IQuizQuestionRow>(quiz_question.post(id), {
            text,
            type,
            add_answers
        })
    }

    static async patch(
        {id: quiz_id}: ID,
        {id}: ID,
        {
            text,
            type,
            add_answers
        }: IPost): Promise<AxiosResponse<IQuizQuestionRow>> {
        return await $qms.patch<IQuizQuestionRow>(quiz_question.patch(quiz_id, id), {
            text,
            type,
            add_answers
        })
    }

    static async get(
        {id: quiz_id}: ID,
        {id}: ID
    ): Promise<AxiosResponse<IQuizQuestionRow>> {
        return await $qms.get<IQuizQuestionRow>(quiz_question.get(quiz_id, id))
    }

    static async delete(
        {id: quiz_id}: ID,
        {id}: ID,
    ): Promise<AxiosResponse> {
        return await $qms.delete(quiz_question.delete(quiz_id, id))
    }
}