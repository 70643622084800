import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {QuizSessionSync} from "../services/quiz_session_sync";
import {ID} from "../models/@others/ID";
import {ISendAnswer} from "../models/quiz_session_sync/params/ISendAnswer";
import {ITimerResponse} from "../models/quiz_session_sync/responses/ITimerResponse";
import {IShowQuestionResponse} from "../models/quiz_session_sync/responses/IShowQuestionResponse";


export class QuizSessionSyncStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly answer = new ServiceStore<[ID, ISendAnswer], any>(QuizSessionSync.answer, false);
    public readonly check_answers = new ServiceStore<[ID], any>(QuizSessionSync.check_answers, true);
    public readonly check_timer = new ServiceStore<[ID], ITimerResponse>(QuizSessionSync.check_timer, false);
    public readonly is_answered = new ServiceStore<[ID], any>(QuizSessionSync.is_answered, false);
    public readonly next_question = new ServiceStore<[ID], any>(QuizSessionSync.next_question, false);
    public readonly show_question = new ServiceStore<[ID], IShowQuestionResponse>(QuizSessionSync.show_question, true);
    public readonly start_timer = new ServiceStore<[ID], any>(QuizSessionSync.start_timer, false);
}