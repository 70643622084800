import {makeObservable, observable} from "mobx";
import {GearStore} from "gears-react";
import en from "../../app/translations/en.json";
import ru from "../../app/translations/ru.json";
import uz from "../../app/translations/uz.json";
import {AccountStore} from "../../V1/stores/AccountStore";
import {FastAuthStore} from "../../V1/stores/FastAuthStore";
import {QuizStore} from "../../QMS/stores/QuizStore";
import {QuizCategoryStore} from "../../QMS/stores/QuizCategoryStore";
import {QuizQuestionStore} from "../../QMS/stores/QuizQuestionStore";
import {QuizSessionStore} from "../../QMS/stores/QuizSessionStore";
import {QuizSessionAsyncStore} from "../../QMS/stores/QuizSessionAsyncStore";
import {quiz_session_async, quiz_session_sync} from "../../QMS/endpoints/qms";
import {QuizSessionSyncStore} from "../../QMS/stores/QuizSessionSyncStore";

export default class Store extends GearStore {
    constructor() {
        super()
        makeObservable(this);
        this.lang.setLanguages([
            // {value: 'uz', label: "O'zbek", JSON: uz},
            {value: 'ru', label: 'Русский', JSON: ru},
            {value: 'en', label: 'English', JSON: en},
        ], 'ru');
    }

    @observable
    public readonly account = new AccountStore();

    @observable
    public readonly fast_auth = new FastAuthStore();

    @observable
    public readonly quiz = new QuizStore();

    @observable
    public readonly quiz_category = new QuizCategoryStore()

    @observable
    public readonly quiz_question = new QuizQuestionStore()

    @observable
    public readonly quiz_session = new QuizSessionStore()

    @observable
    public readonly quiz_session_sync = new QuizSessionSyncStore()

    @observable
    public readonly quiz_session_async = new QuizSessionAsyncStore()

}

