import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Gear} from "gears-react";
import {Observer} from 'mobx-react'
import './app/UI/styles/index.css';
import './app/UI/styles/variables.css';
import './app/UI/assets/fonts/SanFrancisco/stylesheet.css';
import {options} from "./app/UI/styles/mui_theme";
import {access} from "./app/routers/auth/access";
import Store from "./app/stores/store";
import {Context} from './app/context';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-113522530-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme(options);
const store = new Store();

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Context.Provider value={{store}}>
                    <Observer>
                        {() =>
                            <Gear routers={access}/>
                        }
                    </Observer>
            </Context.Provider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
