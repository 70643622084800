import React, {useContext, useEffect} from 'react';
import {ArrowLeft2} from "iconsax-react";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Context} from "../../context";
import {observer} from "mobx-react-lite";

type Props = {
    to: string;
    color?: string;
}

const BackButton = ({to, color}: Props) => {
    const navigate = useNavigate();
    const {store} = useContext(Context);

    return (
        <ArrowLeft2
            onClick={() => {
                console.log('previous_page', store.previous_page.get())
                navigate(store.previous_page.get() !== undefined && store.previous_page.get() !== '' ? store.previous_page.get() : to)
                store.previous_page.set('')
            }}
            size="24"
            color={`${color ? color : '#262626'}`}
            style={{
                cursor: 'pointer',
                flexShrink: 0
            }}
        />
    );
};

export default observer(BackButton);
