import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {ArrowDown2} from "iconsax-react";
import {Divider} from "@mui/material";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import CategorySearch from "./CategorySearch";
import CategoryInput from "./CategoryInput";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {Context} from "../../context";
import {useTranslation} from "react-i18next";

type Props = {
    mainId: number;
    setMainId: Dispatch<SetStateAction<number>>;
    getError: (field: string) => boolean;
    mainValue: string;
    setMainValue: Dispatch<SetStateAction<string>>;
}

const QuizCategoryDrawer = ({setMainId, mainId, getError, setMainValue, mainValue}: Props) => {
    const {store} = useContext(Context);
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [limit, setLimit] = useState<number>(100);
    const [offset, setOffset] = useState<number>(0);
    let array = [0, 0, 0, 0, 0, 0, 0, 0, 0]

    useEffect(() => {
        if (open) {
            store.quiz_category.list.request({limit, offset, ordering: undefined, search}).then(r => {
                console.log(r)
            })
        }
    }, [open, search, limit, offset])

    return (
        <>
            <CategoryChoose
                onClick={() => setOpen(true)}
            >
                <CategoryChooseInner>

                    <CategoryChooseLabel is_label={mainValue.trim() !== ''} style={{
                        color: `${getError('category') ? '#FF5A5F' : mainValue.trim() !== '' ? '#1DA1F2' : '#7F92A0'}`
                    }}>
                        {t("quiz.input.category")} *
                    </CategoryChooseLabel>

                    <CategoryChooseTitle>
                        {mainValue}
                    </CategoryChooseTitle>


                    <ArrowDown2
                        size="24"
                        color="#262626"
                    />
                </CategoryChooseInner>

                <Divider sx={{
                    borderWidth: `${getError('category') ? '1px' : 'none'}`,
                    borderColor: `${getError('category') ? '#FF5A5F' : '#7F92A0'}`
                }}/>
            </CategoryChoose>

            <SwipeableEdgeDrawer open={open} setOpen={setOpen} label={`${t("quiz.drawer.title.categories")}`}>
                <CategorySearch setSearch={setSearch}/>
                <Categories>
                    {
                        store.quiz_category.list.loader.get() ?
                            array.map((e, i) => <CategoryInput key={i}/>) :
                            <>
                                {
                                    store.quiz_category.list.get().results.map((e) =>
                                        <CategoryInput key={e.id}
                                                       value={e.name}
                                                       id={e.id}
                                                       mainValue={mainValue}
                                                       setMainValue={setMainValue}
                                                       mainId={mainId}
                                                       setMainId={setMainId}

                                        />)
                                }

                            </>

                    }
                </Categories>
            </SwipeableEdgeDrawer>
        </>
    );
};

export default observer(QuizCategoryDrawer);


const Categories = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
  padding: 24px 16px 16px 16px;
`;

const CategoryChoose = styled.div`
  margin-top: 28px;
  cursor: pointer;
  width: 100%;

`;
const CategoryChooseInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 14px 0;
  position: relative;
`;

const CategoryChooseTitle = styled.div`
  color: var(--black);
`;

type CategoryChooseLabelProps = {
    is_label: boolean;
}

const CategoryChooseLabel = styled.div`
  position: ${({is_label}: CategoryChooseLabelProps) => is_label ? 'absolute' : 'unset'};
  top: -15px;
  left: -11px;
  transform: ${({is_label}: CategoryChooseLabelProps) => is_label ? 'scale(0.75)' : 'scale(1)'};;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
`;