export const path = {
    login: '/login',
    signup: '/signup',
    content: '/content',
    room: '/room',
    stream: '/streams',
    stream_room: '/stream_room',
    account: '/account',
    shop: '/shop',
    session: '/session',
    quiz: '/quiz',
    questions: '/questions',
    settings: '/settings',
    create: '/create',
    edit: '/edit',
    list: '/list',
    history: '/history',
    creator: '/creator',
    client: '/client',
}