import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {ID} from "../models/@others/ID";
import {QuizQuestion} from "../services/quiz_question";
import {IQuizQuestionRow} from "../models/quiz_question/responses/IQuizQuestionRow";
import {IPost} from "../models/quiz_question/params/IPost";
import {IGetAll} from "../models/quiz_question/params/IGetAll";

export class QuizQuestionStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly list = new ServiceStore<[ID, IGetAll], IListDataResponse<IQuizQuestionRow>>(QuizQuestion.get_all, true);
    public readonly details = new ServiceStore<[ID, ID],IQuizQuestionRow >(QuizQuestion.get, true);

    public readonly create = new ServiceStore<[ID, IPost], IQuizQuestionRow>(QuizQuestion.post, false);
    public readonly change = new ServiceStore<[ID, ID, IPost], IQuizQuestionRow>(QuizQuestion.patch, false);
    public readonly delete = new ServiceStore<[ID, ID], any>(QuizQuestion.delete, false);

}