import {useEffect, useState} from "react";

export interface IError {
    field: string;
    error: boolean;
    message?: string;
}

export const useErrors = (array: IError[], withAlert?: boolean) => {

    const [errors, setErrors] = useState<IError[]>(array)

    const updateErrors = (field: string, error: boolean) => {
        let newArr = [...errors];
        newArr[newArr.findIndex(item => item.field === field)].error = error;
        setErrors(newArr);
    }

    const getError = (field: string) => {
        return errors.filter((obj) => {
            return obj.field === field
        })[0].error
    }

    const findErrorFromArray = (array: IError[]) => {
        array.some((value) => {
            if (value.field === errors[errors.findIndex(item => item.field === value.field)].field) {
                updateErrors(value.field, true)
                if (value.message && withAlert) {
                    alert(value.message)
                }
            }
        })
    }

    return {
        errors,
        getError,
        findErrorFromArray
    }

}
