import React from 'react';
import {observer} from "mobx-react-lite";
import {InputAdornment} from "@mui/material";
import styled from "styled-components";
import {BigTextField} from "./BigTextField";

type Props = {
    index: number,
    text: string,
    correct_answer: boolean,
    onChange: (e: any, index: number) => void,
    onChangeCorrectAnswer: (e: any, index: number) => void
}

const AnswerInput = ({index, text, correct_answer, onChange, onChangeCorrectAnswer}: Props) => {
    return (
        <BigTextField
            required
            fullWidth
            value={text}
            type={'text'}
            onChange={(e) => onChange(e, index)}
            InputProps={{
                autoFocus: false,
                autoComplete: 'off',
                startAdornment: <InputAdornment
                    position="start">{index === 0 ? 'A' : index === 1 ? 'B' : index === 2 ? 'C' : 'D'}:</InputAdornment>,
                endAdornment: <InputAdornment position="end">
                    <CircleButton
                        onClick={(e) => onChangeCorrectAnswer(e, index)}
                    >
                        {correct_answer ?
                            <CircleTrue><Circle/></CircleTrue> :
                            <CircleFalse></CircleFalse>}
                    </CircleButton>
                </InputAdornment>
            }}
            variant="standard"
        />
    );
};

export default observer(AnswerInput);


const CircleButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
`
const CircleTrue = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #00C1C1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00C1C1;
`
const CircleFalse = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #7F92A0;
`