import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import FilterDrawer from "../FilterDrawer";
import styled from "styled-components";
import {ToggleOffCircle, ToggleOnCircle} from "iconsax-react";
import {useTranslation} from "react-i18next";

type Props = {
    type: number | null;
    setType: Dispatch<SetStateAction<number | null>>
}

const QuestionFilters = ({setType, type}: Props) => {
    const {t} = useTranslation();

    return (
        <div>
            <FilterDrawer active={type !== null}>
                <StartLabel onClick={() => {
                    if (type === 1) {
                        setType(null)
                    } else {
                        setType(1)
                    }
                }}>
                    <StartLabelTitle>{t("quiz.question.list.row.subtitle.question.type.1")}</StartLabelTitle>

                    {
                        type === 1 ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }

                </StartLabel>
                <StartLabel onClick={() => {
                    if (type === 2) {
                        setType(null)
                    } else {
                        setType(2)
                    }
                }}>
                    <StartLabelTitle>{t("quiz.question.list.row.subtitle.question.type.2")}</StartLabelTitle>

                    {
                        type === 2 ?
                            <ToggleOnCircle
                                size="24"
                                color="#2BCBBA"
                            />
                            : <ToggleOffCircle
                                size="24"
                                color="#7F92A0"
                            />
                    }

                </StartLabel>
            </FilterDrawer>
        </div>
    );
};

export default observer(QuestionFilters);

const StartLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
const StartLabelTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: var(--gray);
  user-select: none;
  -webkit-user-select: none;
`;
