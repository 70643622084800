import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {FastAuth} from "../services/fast_auth";
import {IPost} from "../models/fast_auth/params/IPost";
import {IFastAuthPost} from "../models/fast_auth/responses/IFastAuthPost";

export class FastAuthStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly auth = new ServiceStore<[IPost], IFastAuthPost>(FastAuth.post, false);
}