import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import PopupButton from "../../../PopupButton";
import {PopupContainer, PopupContainerTitle} from "../../../PopupComponents";
import {BigButton} from "../../../BigButton";
import {Context} from "../../../../../context";

const SyncSessionStartButton = () => {
    const {store} = useContext(Context);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const nextQuestion = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)
        store.quiz_session_sync.next_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status == 200) {
                store.quiz_session_sync.start_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                    console.log(r)

                    if (r.status == 200) {

                        store.quiz_session_sync.check_timer.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setTimer(r.data.timer)
                            }
                        })

                        store.quiz_session_sync.show_question.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setShowQuestions(true)
                            }
                        })

                        store.quiz_session_sync.check_answers.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                            if (r.status === 200) {
                                // setAnswers(r.data.answers)
                            }
                        })

                        store.quiz_session.close_registration.request({id: store.quiz_session.details.get().id}).then(r => {
                            console.log(r)
                        })
                    }
                })
            } else if (r.status == 204) {
                store.quiz_session.show_result.request({id: store.quiz_session.details.get().id}, {}).then(r => {
                    console.log(r)
                    if (r.status === 200) {
                        // setShowResult(true)
                    }
                })

            }
        })
    }

    return (
        <PopupButton title={'Начать'} variant={'contained'} setOpen={setOpen} open={open}>
            <PopupContainer>
                <PopupContainerTitle>Начать quiz ?</PopupContainerTitle>
                <BigButton
                    fullWidth
                    onClick={nextQuestion}
                    variant="contained"
                    color={'primary'}
                    loading={loading}
                >
                    Да
                </BigButton>
                <BigButton
                    fullWidth
                    variant="outlined"
                    color={'error'}
                    onClick={() => setOpen(false)}
                >
                    Нет
                </BigButton>
            </PopupContainer>
        </PopupButton>
    );
};

export default observer(SyncSessionStartButton);