import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../context";
import styled from "styled-components";
import {MyRow} from "../../MyRow";
import BackButton from "../../BackButton";
import {path} from "../../../../routers/routers";
import Typography from "@mui/material/Typography";
import {BigButton} from "../../BigButton";
import {useNavigate} from "react-router-dom";
import {BottomPos} from "../../BottomPos";
import PageLoader from "../../PageLoader";
import Centrifuge from "centrifuge";
import {socket} from "../../../../../QMS/endpoints/ws";

const ClientShowResult = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {

        showResult()

        const centrifuge = new Centrifuge(socket.connect());
        centrifuge.setToken(store.quiz_session.details.get().cent_token)

        let callbacks = {
            "publish": function (ctx: any) {
                console.log(ctx.data);

                if (ctx.data.reason === 'show_result') {
                    showResult()
                }
            },
        }

        centrifuge.presence(`public:channel`)
        centrifuge.subscribe(`quiz_session.${store.quiz_session.details.get().id}`, callbacks);
        centrifuge.connect()

    }, [])

    function showResult() {
        store.quiz_session.show_my_result.request({
            id: store.quiz_session.details.get().id,
            history: false
        }).then(r => {
            console.log(r)
        })
    }


    if (store.quiz_session.show_my_result.loader.get()) return <PageLoader/>

    if (store.quiz_session.show_my_result.get().place !== null) return (
        <Root>

            <Top>
                <MyRow>
                    <BackButton to={path.session} color={'#fff'}/>
                    <p style={{color: '#fff'}}>{store.quiz_session.details.get().quiz.name}</p>
                </MyRow>

                <Prize>
                    Приз {store.quiz_session.details.get().reward}
                </Prize>

                <MyRow style={{
                    justifyContent: 'center'
                }}>

                    <p style={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#fff',
                        marginTop: '12px',
                        width: '90%',
                        textAlign: 'center',
                        wordBreak: 'break-word'
                    }}>
                        {store.quiz_session.details.get().quiz.description}
                    </p>
                </MyRow>

            </Top>


            <Container>

                <Titles>
                    <Typography sx={{
                        alignSelf: 'center',
                        fontWeight: '700',
                        fontSize: '24px'
                    }}>
                        {store.quiz_session.show_my_result.get().place === 1 && store.quiz_session.show_my_result.get().right_answer > 0 ? <>
                            <div>
                                Поздравляем!
                            </div>
                            <div>
                                Вы победитель
                            </div>
                        </> : <>
                            <div>
                                Вы проиграли :(
                            </div>
                        </>}
                    </Typography>
                    <Typography sx={{
                        alignSelf: 'center',
                        color: 'text.primary',
                        fontSize: '16px'
                    }}>
                        <div>
                            Правильно ответили на {store.quiz_session.show_my_result.get().right_answer + " "}
                            вопросов из {store.quiz_session.details.get().question_number + " "}
                        </div>
                        <div>
                            Время потрачено {store.quiz_session.show_my_result.get().sum_answer_time || 0} сек.
                        </div>
                        <div>
                            {store.quiz_session.show_my_result.get().place !== 1 && `Заняли ${store.quiz_session.show_my_result.get().place} место`}
                        </div>
                    </Typography>

                </Titles>

                {store.quiz_session.show_my_result.get().place !== 1
                    && (!store.quiz_session.show_result.loader.get() &&
                    store.quiz_session.show_result.get().count > 1
                        ?
                        <Titles>
                            <Typography sx={{
                                alignSelf: 'center',
                                fontWeight: '700',
                                fontSize: '24px',
                                color: 'text.secondary',
                            }}>
                                <div>
                                    Ваш соперник {store.quiz_session.show_result.get().results[0]!.name} выиграл
                                </div>
                            </Typography>
                            <Typography sx={{
                                alignSelf: 'center',
                                color: 'text.secondary',
                                fontSize: '16px'
                            }}>
                                <div>
                                    Правильно ответил
                                    на {store.quiz_session.show_result.get().results[0]!.right_answer + " "}
                                    вопросов из {store.quiz_session.details.get().question_number + " "}
                                </div>
                                <div>
                                    Время
                                    потрачено {store.quiz_session.show_result.get().results[0]!.sum_answer_time + " "}
                                    секунды
                                </div>
                                <div>
                                    Занял {store.quiz_session.show_result.get().results[0]!.place} место
                                </div>
                            </Typography>
                        </Titles>
                        :
                        <div>Загружаем результаты...</div>)
                }


            </Container>

            <BottomPos>
                {store.quiz_session.show_my_result.get().place !== 1 && <BlueTitle>
                    Не расстраивайтесь вернитесь на главное
                    меню и испытывайте себя в других
                    QUIZ
                </BlueTitle>}


                <BigButton
                    onClick={() => {
                        navigate(path.session)
                    }}
                    fullWidth
                    variant="contained"
                >
                    Другие квизы
                </BigButton>
            </BottomPos>
        </Root>
    );

    return (
        <Root>

            <Top>
                <MyRow>
                    <BackButton to={path.session} color={'#fff'}/>
                    <p style={{color: '#fff'}}>{store.quiz_session.details.get().quiz.name}</p>
                </MyRow>

                <Prize>
                    Приз null
                </Prize>

                <MyRow style={{
                    justifyContent: 'center'
                }}>

                    <p style={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#fff',
                        marginTop: '12px',
                        width: '90%',
                        textAlign: 'center',
                        wordBreak: 'break-word'
                    }}>
                        {store.quiz_session.details.get().quiz.description}
                    </p>
                </MyRow>

            </Top>


            <Container>

                <Titles>
                    <Typography sx={{
                        alignSelf: 'center',
                        fontWeight: '700',
                        fontSize: '24px'
                    }}>
                        Ждем результатов
                    </Typography>
                    <Typography sx={{
                        alignSelf: 'center',
                        color: 'text.primary',
                        fontSize: '16px'
                    }}>
                        Они скоро появятся!
                    </Typography>

                </Titles>


            </Container>

            <BottomPos>
                {/*{store.quiz_session.show_my_result.get().place !== 1 && <BlueTitle>*/}
                {/*    Не расстраивайтесь вернитесь на главное*/}
                {/*    меню и испытывайте себя в других*/}
                {/*    QUIZ*/}
                {/*</BlueTitle>}*/}


                <BigButton
                    onClick={() => {
                        navigate(path.session)
                    }}
                    fullWidth
                    variant="contained"
                >
                    Другие квизы
                </BigButton>
            </BottomPos>
        </Root>
    );

};

export default observer(ClientShowResult);


const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Top = styled.div`
  width: 100%;
  position: relative;
  height: 250px;
  padding: 24px 16px;
  background-color: #1DA1F2;
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding: 16px 16px 150px 16px;
  gap: 24px;
`;

const Titles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
`;


const BlueTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--main);
  margin: 48px 0 24px 0;
`;

const Prize = styled.div`
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
  word-break: break-word;
  margin-top: 16px;
`;