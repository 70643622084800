import React, {useContext, useState} from 'react';
import SyncSessionQuestionResults from "./SyncSessionQuestionResults";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../context";
import {Divider} from "@mui/material";
import {BottomPos} from "../../../BottomPos";
import styled from "styled-components";
import CloseSessionButton from "../CloseSessionButton";
import SyncSessionNextQuestionButton from "./SyncSessionNextQuestionButton";

const SyncCreatorShowQuestion = () => {
    const {store} = useContext(Context);

    return (
        <Root>
            <Container>
                <Title>
                    Вопрос {store.quiz_session_sync.show_question.get().question_index}
                </Title>
                <Question>{store.quiz_session_sync.show_question.get().text}</Question>
                <Divider/>
                <SyncSessionQuestionResults/>
            </Container>
            <BottomPos>
                <SyncSessionNextQuestionButton/>
                <CloseSessionButton/>
            </BottomPos>
        </Root>
    );
};

export default observer(SyncCreatorShowQuestion);

const Root = styled.div`
  width: 100%;
  padding: 16px 16px calc(20vh + 50px) 16px;

  @media (max-height: 730px) {
    padding: 16px 16px calc(30vh + 100px) 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Question = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  width: 100%;
  word-break: break-word;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  width: 100%;
  word-break: break-word;
`