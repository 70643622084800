import axios from "axios";

export const $qms = axios.create({
    // baseURL: 'https://py.dwed.biz',
})

$qms.interceptors.request.use((config) => {
    if (!config?.headers) {
        throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
    }
    if (localStorage.getItem('access')) {
        config.headers.Authorization = `Token ${localStorage.getItem('access')!}`;
    }
    // config.headers.x_lang = `${JSON.parse(localStorage.getItem('language')!)}`;
    return config;
})
