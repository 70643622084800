import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {MyRow, MyRowSpaceBetween} from "./MyRow";
import styled from "styled-components";
import {Eye} from "iconsax-react";
import {useProgressiveImage} from "../../hooks/useProgressiveImage";


const StreamDetails = () => {
    const [hidden, setHidden] = useState<boolean>(true);
    const logo = useProgressiveImage('https://yt3.ggpht.com/KNYElmLFGAOSZoBmxYGKKXhGHrT2e7Hmz3WsBerbam5uaDXFADAmT7htj3OcC-uK1O88lC9fQg=s68-c-k-c0x00ffffff-no-rj')


    return (
        <Root>
            <Content>
                <Part>
                    <Relative>
                        <Logo src={logo}/>
                        <Live>
                            Live
                        </Live>
                    </Relative>
                </Part>
                <Part style={{
                    width: '100%'
                }}>
                    <Title>
                        lofi hip hop radio - beats to relax/study to
                    </Title>
                    <MyRowSpaceBetween>
                        <Subtitle>
                            Lofi Girl
                        </Subtitle>
                        <MyRow style={{
                            gap: '4px',
                            width: 'auto'
                        }}>
                            <Eye
                                size="24"
                                color="#262626"
                            />
                            <ViewsTitle>10.5K</ViewsTitle>
                        </MyRow>
                    </MyRowSpaceBetween>

                    <MyRow style={{
                        gap: '4px'
                    }}>
                        <MyChip>Music</MyChip>
                        <MyChip>Lofi</MyChip>
                        <MyChip>Relax</MyChip>
                    </MyRow>
                </Part>
            </Content>
            <Body>
                <Title>
                    Description
                </Title>
                <About>
                    <Text is_hidden={hidden}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis magna orci, sit accumsan
                        scelerisqe. Vehicula arcu, scelerisque id in. Velit, iaculis sem purus lobortis. Adipiscing quam
                        egestas odio habitant eget massa. Suspendisse proin et diam tellus arcu
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis magna orci, sit accumsan
                        scelerisqe. Vehicula arcu, scelerisque id in. Velit, iaculis sem purus lobortis. Adipiscing quam
                        egestas odio habitant eget massa. Suspendisse proin et diam tellus arcu
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis magna orci, sit accumsan
                        scelerisqe. Vehicula arcu, scelerisque id in. Velit, iaculis sem purus lobortis. Adipiscing quam
                        egestas odio habitant eget massa. Suspendisse proin et diam tellus arcu
                    </Text>
                    <More onClick={() => setHidden(!hidden)}>
                        {hidden ? 'больше' : 'спрятать'}
                    </More>
                </About>
            </Body>
            <Body>
                <Title>
                    Today's schedule
                </Title>

                <MyRow style={{
                    gap: '5px',
                    marginTop: '24px',
                    flexWrap: 'wrap'
                }}>

                    <Relative style={{
                        width: '90px',
                        height: '24px',
                        flexShrink: '0'
                    }}>
                        <WatchingAvatar style={{
                            left: 0
                        }}
                                        src={'https://img.freepik.com/premium-photo/realistic-portrait-fictional-punk-girl-with-headphones-pink-hair_158863-560.jpg'}/>
                        <WatchingAvatar style={{
                            left: 16
                        }}
                                        src={'https://img.freepik.com/premium-photo/realistic-portrait-fictional-punk-girl-with-headphones-pink-hair_158863-560.jpg'}/>
                        <WatchingAvatar style={{
                            left: 32
                        }}
                                        src={'https://img.freepik.com/premium-photo/realistic-portrait-fictional-punk-girl-with-headphones-pink-hair_158863-560.jpg'}/>
                        <WatchingAvatar style={{
                            left: 48
                        }}
                                        src={'https://img.freepik.com/premium-photo/realistic-portrait-fictional-punk-girl-with-headphones-pink-hair_158863-560.jpg'}/>
                        <WatchingAvatar style={{
                            left: 64
                        }}
                                        src={'https://img.freepik.com/premium-photo/realistic-portrait-fictional-punk-girl-with-headphones-pink-hair_158863-560.jpg'}/>
                    </Relative>

                    <ScheduleTitle>
                        Watching 10 friends and 10.5K others
                    </ScheduleTitle>
                </MyRow>

            </Body>
        </Root>
    );
};

export default observer(StreamDetails);


const Root = styled.div`
  width: 100%;
  padding: 16px;
`
const Body = styled.div`
  width: 100%;
  margin-top: 24px;
`

const About = styled.div`
  width: 100%;
  margin-top: 8px;

`

type TextProps = {
    is_hidden: boolean
}

const Text = styled.div`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: ${({is_hidden}: TextProps) => is_hidden ? '6' : 'none'};
  -webkit-box-orient: vertical;
  overflow: ${({is_hidden}: TextProps) => is_hidden ? 'hidden' : 'unset'};

`

const More = styled.div`
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`

const Logo = styled.img`
  width: 48px;
  height: 48px;
  border: 2px solid #FF5A5F;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`
const Relative = styled.div`
  position: relative;
`

const Live = styled.div`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF5A5F;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  width: 35px;
  height: 18px;
  text-transform: uppercase;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const Part = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

`


const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #262626;

`

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #7F92A0;
  flex-shrink: 0;
`


const MyChip = styled.div`
  background: #F2F2F2;
  border-radius: 100px;
  font-weight: 400;
  font-size: 10px;
  color: #7F92A0;
  padding: 4px 8px;
`


const ViewsTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #262626;
`
const ScheduleTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #7F92A0;
`


const WatchingAvatar = styled.img`
  position: absolute;
  border-radius: 50%;
  background-color: black;
  border: 2px solid white;
  width: 24px;
  height: 24px;
  top: 0;
`