import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {Quiz} from "../services/quiz";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {IQuizRow} from "../models/quiz/responses/IQuizRow";
import {ID} from "../models/@others/ID";
import {IGetAll} from "../models/quiz/params/IGetAll";
import {IPost} from "../models/quiz/params/IPost";
import {IQuizSession} from "../models/quiz/params/IQuizSession";
import {IQuizSessionResponse} from "../models/quiz/responses/IQuizSessionResponse";

export class QuizStore {
    constructor() {
        makeAutoObservable(this);
    }


    public readonly list = new ServiceStore<[IGetAll], IListDataResponse<IQuizRow>>(Quiz.get_all, true);
    public readonly details = new ServiceStore<[ID], IQuizRow>(Quiz.get, true);

    public readonly create = new ServiceStore<[IPost], IQuizRow>(Quiz.post, false);
    public readonly change = new ServiceStore<[ID, IPost], IQuizRow>(Quiz.patch, false);
    public readonly delete = new ServiceStore<[ID], any>(Quiz.delete, false);

    public readonly new_session = new ServiceStore<[ID, IQuizSession], IQuizSessionResponse>(Quiz.new_session, false);
}