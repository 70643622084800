import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {path} from "../../../../routers/routers";
import {Context} from "../../../../context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PopupButton from "../../PopupButton";
import {PopupContainer, PopupContainerTitle} from "../../PopupComponents";
import {BigButton} from "../../BigButton";

const SessionShowResultButton = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);


    const showResult = () => {
        store.quiz_session.calculate_places.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
        })
    }

    return (
        <PopupButton title={'Показать результаты'} color={'success'} variant={'contained'} setOpen={setOpen}
                     open={open}>
            <PopupContainer>
                <PopupContainerTitle>Вы действительно хотите показать их?</PopupContainerTitle>
                <BigButton
                    onClick={showResult}
                    fullWidth
                    type={'submit'}
                    variant="contained"
                >
                    Да
                </BigButton>
                <BigButton
                    fullWidth
                    variant="outlined"
                    color={'error'}
                    onClick={() => setOpen(false)}
                >
                    Нет
                </BigButton>
            </PopupContainer>

        </PopupButton>
    );
};

export default observer(SessionShowResultButton);