import styled from "styled-components";
import {MAX_WIDTH_PAGE} from "../../utils/utils";

export const Header = styled.div`
  position: fixed;
  height: var(--header-height);
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  //box-shadow: 0px 2px 10px rgba(127, 146, 160, 0.15);

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;


  z-index: 1000;
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @media(min-width: 1030px){
    max-width: ${MAX_WIDTH_PAGE}px;
    left: 50%;
    transform: translateX(-50%);
  }
  
`;

export const BigHeader = styled.div`
  position: fixed;
  height: calc(var(--header-height) * 2.144);
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  //box-shadow: 0px 2px 10px rgba(127, 146, 160, 0.15);

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  
  z-index: 1000;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  @media(min-width: 1030px){
    max-width: ${MAX_WIDTH_PAGE}px;
    left: 50%;
    transform: translateX(-50%);
  }
  
`;