import styled from "styled-components";
import React, {useContext, useEffect, useState} from 'react';
import {Avatar, IconButton} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Bag2, Category2, User, UserSquare} from "iconsax-react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {path} from "../../routers/routers";
import {Context} from "../../context";
import {useProgressiveImage} from "../../hooks/useProgressiveImage";
import image from "../assets/images/Screen.svg";
import {MAX_WIDTH_PAGE} from "../../utils/utils";

const Footer = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [current_page, setCurrentPage] = useState<string>('');

    useEffect(() => {
        setCurrentPage(location.pathname.split('/')[1])
    }, [location.pathname])

    const loaded = useProgressiveImage(store.account.details.get().avatar)

    return (
        <Root>

            <Root2>
                <IconButton
                    sx={{
                        flexShrink: '0'
                    }}
                    onClick={() => navigate('/')}
                >
                    <Category2
                        size="24"
                        color={current_page === '' ? '#1DA1F2' : '#262626'}
                    />
                </IconButton>

                {/*<IconButton*/}
                {/*    sx={{*/}
                {/*        flexShrink: '0'*/}
                {/*    }}*/}
                {/*    onClick={() => navigate(path.shop)}*/}
                {/*>*/}
                {/*    <Bag2*/}
                {/*        size="24"*/}
                {/*        color={current_page === path.shop.split('/')[1] ? '#1DA1F2' : '#262626'}*/}
                {/*    />*/}
                {/*</IconButton>*/}

                <IconButton
                    sx={{
                        flexShrink: '0'
                    }}
                    onClick={() => navigate(path.account)}
                >
                    <Avatar
                        src={loaded}
                        sx={{width: 24, height: 24, backgroundColor: 'rgba(255,255,255,0)'}}
                    >
                        <User
                            size="24"
                            color="#7F92A0"
                        />
                    </Avatar>
                </IconButton>
            </Root2>

        </Root>
    );
};

export default observer(Footer);

// const Root = styled.div`
//   position: fixed;
//   height: var(--header-height);
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   background-color: #ffffff;
//   box-shadow: 0px -2px 10px rgba(127, 146, 160, 0.15);
//   z-index: 99999;
//   gap: 24px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0 16px;
// `;

const Root = styled.div`
  position: fixed;
  height: var(--header-height);
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px -2px 10px rgba(127, 146, 160, 0.15);
  z-index: 99999;
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @media(min-width: 1030px){
    max-width: ${MAX_WIDTH_PAGE}px;
    left: 50%;
    transform: translateX(-50%);
  }

`;

const Root2 = styled.div`
  height: var(--header-height);
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 75px;
`;