import React, {useRef} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {Context} from "../../context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import InputMask from "react-input-mask";
import logo from '../assets/images/Logo.svg';
import {IMaskInput} from 'react-imask';

import {
    CssBaseline,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {ArrowDown2, Eye, EyeSlash} from "iconsax-react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import MyCheckbox from "../atoms/MyCheckbox";
import {BigButton} from "../atoms/BigButton";
import {path} from "../../routers/routers";
import {BigTextField} from "../atoms/BigTextField";
import TranslationButton from "../atoms/TranslationButton";
import {IError, useErrors} from "../../hooks/useErrors";

const Body = observer(() => {
    const {store} = useContext(Context);

    const error_array: IError[] = [
        {field: 'phone', error: false}
    ]

    const {errors, getError, findErrorFromArray} = useErrors(error_array, true)

    const navigate = useNavigate();
    const {t} = useTranslation();

    const [phone, setPhone] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);

    const ref = useRef(null);
    const inputRef = useRef(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const _phone = phone.replace(/[\s.?*^$[\]\\(){}|-]/g, "")
        store.fast_auth.auth.request({phone: _phone}).then((r) => {
            console.log(r)
            if (r!.status === 200) {
                localStorage.setItem('access', r!.data.access);
                localStorage.setItem('refresh', r!.data.refresh);
                store.account.setIsAuth(true);
                navigate('/')
            } else {
                findErrorFromArray(r?.data?.errors)
            }
        })
    };


    return (
        <Root>
            <Logo>

                <Image src={logo} alt={'logo'}/>

                <LeftPos>
                    <TranslationButton color={"white"}/>
                </LeftPos>
            </Logo>
            <Content onSubmit={handleSubmit}>
                <Titles>
                    <Typography sx={{
                        fontWeight: '700',
                        fontSize: '24px',
                        textAlign: 'center',
                        textTransform: 'uppercase'
                    }}>
                        {t("login.title")}
                    </Typography>
                    <Typography sx={{
                        color: 'text.secondary',
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>
                        {t("login.subtitle")}
                    </Typography>
                </Titles>

                {/*<IMaskInput*/}
                {/*    mask="+998-(00)-000-00-00"*/}
                {/*    // definitions={{ "#": /[1-9]/ }}*/}
                {/*    // mask="+\9\98 (99) 999 99 99"*/}
                {/*    radix="_"*/}
                {/*    value={phone}*/}
                {/*    unmask={true} // true|false|'typed'*/}
                {/*    ref={ref}*/}
                {/*    inputRef={inputRef}  // access to nested input*/}
                {/*    onAccept={*/}
                {/*        (value, mask) => {*/}
                {/*            if (value) setPhone(value.toString())*/}
                {/*        }*/}
                {/*    }*/}
                {/*/>*/}


                <InputMask
                    mask="+\9\98 (99) 999 99 99"
                    value={phone}
                    disabled={false}
                    onChange={(e) => setPhone(e.target.value)}
                >
                    <BigTextField
                        error={getError('phone')}
                        label={`${t("login.input.phone")}`}
                        type={'tel'}
                        required
                        fullWidth
                        variant={'standard'}
                    />
                </InputMask>


                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Login"*/}
                {/*    variant="standard"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Password"*/}
                {/*    type={showPassword ? 'text' : 'password'}*/}
                {/*    InputProps={{*/}
                {/*        endAdornment: <InputAdornment position="start">*/}
                {/*            <IconButton*/}
                {/*                aria-label="toggle password visibility"*/}
                {/*                onClick={() => setShowPassword(!showPassword)}*/}
                {/*                edge="end"*/}
                {/*            >*/}
                {/*                {showPassword ?*/}
                {/*                    <EyeSlash*/}
                {/*                        size="24"*/}
                {/*                        color="#7F92A0"*/}
                {/*                    />*/}
                {/*                    :*/}
                {/*                    <Eye*/}
                {/*                        size="24"*/}
                {/*                        color="#262626"*/}

                {/*                    />*/}
                {/*                }*/}
                {/*            </IconButton>*/}
                {/*        </InputAdornment>,*/}
                {/*    }}*/}
                {/*    variant="standard"*/}
                {/*/>*/}

                {/*<Row>*/}
                {/*    <RememberMe>*/}
                {/*        <MyCheckbox/>*/}
                {/*        <Typography sx={{*/}
                {/*            color: 'text.secondary',*/}
                {/*            fontSize: '12px'*/}
                {/*        }}>*/}
                {/*            Remember me*/}
                {/*        </Typography>*/}
                {/*    </RememberMe>*/}
                {/*    <MyLink>Forget password?</MyLink>*/}
                {/*</Row>*/}

                <BigButton
                    type="submit"
                    loading={store.fast_auth.auth.loader.get()}
                    fullWidth
                    variant="contained"
                >
                    {t("login.button.submit")}
                </BigButton>

                {/*<Or>*/}
                {/*    <Divider sx={{*/}
                {/*        borderColor: `var(--gray)`*/}
                {/*    }}/>*/}
                {/*    <OrTitle>*/}
                {/*        or*/}
                {/*    </OrTitle>*/}
                {/*</Or>*/}

                {/*<BigButton*/}
                {/*    onClick={() => navigate(path.signup)}*/}
                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*    color={"inherit"}*/}
                {/*>*/}
                {/*    Create New Account*/}
                {/*</BigButton>*/}

            </Content>
        </Root>
    );
});

const template: ITemplate = {
    body: <Body/>
}

export const login = [template]


const Root = styled.div`

`;


const Content = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
`;

const Titles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

const MyLink = styled(Link)`
  text-decoration: none !important;
  cursor: pointer;
  font-size: 12px;

  :hover {
    text-decoration: underline !important;
  }
`;

const Or = styled.div`
  position: relative;
  width: 100%;
`;

const OrTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  width: 60px;
  color: var(--gray);
  font-size: 12px;
  font-weight: 400;
  display: flex;
  text-align: left;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  height: 254px;
  background-color: #1DA1F2;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const LeftPos = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const Image = styled.img`
  width: 88px;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`;