import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../context";
import Centrifuge from "centrifuge";
import {socket} from "../../../../../QMS/endpoints/ws";
import PageLoader from "../../PageLoader";
import ClientRoom from "./ClientRoom";
import ClientShowQuestion from "./ClientShowQuestion";
import ClientShowResult from "./ClientShowResult";

const Client = () => {
    const {store} = useContext(Context);

    const [show_question, setShowQuestions] = useState<boolean>(false);
    const [show_result, setShowResult] = useState<boolean>(false);
    const [reconnect, setReconnect] = useState<boolean>(false);
    const [finishSession, setFinishSession] = useState<boolean>(false);


    const showQuestionSync = () => {
        store.quiz_session_sync.show_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setShowQuestions(true)
                setShowResult(false)
            } else if (r.status === 204) {
                setShowQuestions(false)
                setShowResult(true)
            } else {
                setShowQuestions(false)
                setShowResult(false)
            }
        })
    }

    const showQuestionAsync = () => {
        store.quiz_session_async.show_question.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setShowQuestions(true)
                setShowResult(false)
            } else if (r.status === 204) {
                setShowQuestions(false)
                setShowResult(true)
            } else {
                setShowQuestions(false)
                setShowResult(false)
            }
        })
    }

    useEffect(() => {
        store.quiz_session.reconnect.loader.set(true);
        store.quiz_session_sync.show_question.loader.set(true)
        store.quiz_session.reconnect.request({id: store.quiz_session.details.get().id}).then(r => {
            console.log(r)
            if (r.status === 200) {
                setReconnect(true)
                if (store.quiz_session.details.get().type === 1) {
                    showQuestionSync();
                } else if (store.quiz_session.details.get().type === 2) {
                    showQuestionAsync()
                }
            } else {
                store.quiz_session_sync.show_question.loader.set(false)
            }
        })

        return () => {
            store.quiz_session.reconnect.loader.set(true);
            store.quiz_session_sync.show_question.loader.set(true)
        }
    }, [])

    useEffect(() => {

        if (store.quiz_session.details.get().type === 1) {
            showQuestionSync()
        } else if (store.quiz_session.details.get().type === 2) {
            showQuestionAsync()
        }

        const centrifuge = new Centrifuge(socket.connect());
        centrifuge.setToken(store.quiz_session.details.get().cent_token)

        let callbacks = {
            "publish": function (ctx: any) {
                console.log(ctx.data);
                if (reconnect) {
                    if (ctx.data.reason === 'show_result') {
                        setShowResult(true)
                    }
                    if (ctx.data.reason === 'show_question') {
                        if (store.quiz_session.details.get().type === 1) {
                            showQuestionSync()
                        }
                    }
                }

                if (ctx.data.reason === 'finish_session') {
                    setFinishSession(true)
                }
            },
        }

        centrifuge.presence(`public:channel`)
        centrifuge.subscribe(`quiz_session.${store.quiz_session.details.get().id}`, callbacks);
        centrifuge.connect()

    }, [reconnect])

    if (store.quiz_session.details.get().type === 1) {
        if (store.quiz_session.reconnect.loader.get() || store.quiz_session_sync.show_question.loader.get()) return <PageLoader/>

    } else if (store.quiz_session.details.get().type === 2) {
        if (store.quiz_session.reconnect.loader.get() || store.quiz_session_async.show_question.loader.get()) return <PageLoader/>
    }

    if (show_result && !finishSession) return <ClientShowResult/>
    if (show_question && !finishSession) return <ClientShowQuestion showQuestionAsync={showQuestionAsync}/>

    return <ClientRoom
        showQuestionAsync={showQuestionAsync}
        finishSession={finishSession}
        reconnect={reconnect}
        setReconnect={setReconnect}/>
};

export default observer(Client);

