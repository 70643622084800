import {IGetAll} from "../models/quiz/params/IGetAll";
import {AxiosResponse} from "axios";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {$qms} from "../http";
import {ISendAnswer} from "../models/quiz_session_sync/params/ISendAnswer";
import {ID} from "../models/@others/ID";
import {quiz_session_sync} from "../endpoints/qms";
import {ITimerResponse} from "../models/quiz_session_sync/responses/ITimerResponse";
import {IShowQuestionResponse} from "../models/quiz_session_sync/responses/IShowQuestionResponse";

export class QuizSessionSync {

    static async answer({id}: ID, {answer}: ISendAnswer): Promise<AxiosResponse> {
        return await $qms.post(quiz_session_sync.answer(id), {
            answer
        })
    }

    static async check_answers({id}: ID): Promise<AxiosResponse> {
        return await $qms.get(quiz_session_sync.check_answers(id))
    }

    static async check_timer({id}: ID): Promise<AxiosResponse<ITimerResponse>> {
        return await $qms.get<ITimerResponse>(quiz_session_sync.check_timer(id))
    }

    static async is_answered({id}: ID): Promise<AxiosResponse> {
        return await $qms.get(quiz_session_sync.is_answered(id))
    }

    static async next_question({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session_sync.next_question(id))
    }

    static async show_question({id}: ID): Promise<AxiosResponse<IShowQuestionResponse>> {
        return await $qms.get<IShowQuestionResponse>(quiz_session_sync.show_question(id))
    }

    static async start_timer({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session_sync.start_timer(id))
    }

}