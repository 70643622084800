import styled from "styled-components";

export const Copied = styled.div`
  position: absolute;
  bottom: -20px;
  margin: 0 auto;
  //width: 50%;
  height: 16px;
  padding: 0 6px;
  background-color: #262626;
  color: white;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  animation-name: example;
  animation-duration: 1.5s;
  opacity: 0;
  @keyframes example {
    from {
      transform: translateY(-10px);
      opacity: 0.7;
    }
    to {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
`
