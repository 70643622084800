import React from "react";
import {ITemplate} from "gears-react";
// import Error from "../atoms/Error";

const template: ITemplate = {
    body: <>Error</>
}

export const error = [template]

// import {observer} from "mobx-react";
// import styled from "styled-components";
// import {useContext, useEffect, useState} from "react";
// import {Context} from "../../LOGIC/context";
// import {useNavigate} from "react-router-dom";
// import {useTranslation} from "react-i18next";
// import {ITemplate} from "gears-react";
//
// const Body = observer(() => {
//     const {store} = useContext(Context);
//     const navigate = useNavigate();
//     const {t} = useTranslation();
//
//
//     return <Root>
//
//     </Root>
// });
//
// const template: ITemplate = {
//     body: <Body/>
// }
//
// export default [template];
//
// const Root = styled.div`
//
// `;
