import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Skeleton, Tooltip} from "@mui/material";
import {ShoppingCart} from "iconsax-react";

type Props = {
    title?: string;
    img?: string;
    onClick?: (...args: any) => void;
    subtitle?: string;
}

const PageLinkCard = ({img, title, onClick, subtitle}: Props) => {
    const {t} = useTranslation();

    return (
        <Root onClick={onClick!} style={{
            backgroundColor: `${title ? 'white' : 'none'}`,
            boxShadow: `${title ? 'rgba(127, 146, 160, 0.15) 0px 7px 29px 0px' : 'none'}`
        }}>

            {img ? <Image style={{
                backgroundImage: `url(${img!})`
            }}/> : <Skeleton variant="rounded" width={`100%`} height={100}/>}

            <Content>
                <Tooltip title={title}>
                    <Name>
                        {title || <Skeleton variant="rounded" width={`100%`} height={20}/>}
                    </Name>
                </Tooltip>

                {subtitle && <Subtitles>
                    <Subtitle>
                        <SubtitleKey>{subtitle}</SubtitleKey>
                    </Subtitle>
                </Subtitles>}

            </Content>

        </Root>


    );
};

export default observer(PageLinkCard);

export const Root = styled.div`
  cursor: pointer;
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 8px;

`;


export const Content = styled.div`
  width: 100%;
`;

export const Image = styled.div`
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Name = styled.div`
  user-select: none;
  -webkit-user-select: none;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #262626;
  white-space: nowrap;
  margin: 8px 0;
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const Subtitles = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const SubtitleKey = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #7F92A0;
  white-space: nowrap;
`
