import {AxiosResponse} from "axios";
import {fast_auth} from "../endpoints/v1";
import {IFastAuthPost} from "../models/fast_auth/responses/IFastAuthPost";
import {IPost} from "../models/fast_auth/params/IPost";
import {$v1} from "../http";

export class FastAuth {
    static async post(
        {
            phone
        }: IPost,
    ): Promise<AxiosResponse<IFastAuthPost>> {
        return await $v1.post<IFastAuthPost>(fast_auth.post(), {
            phone
        })
    }
}