import {getPages, routes_template} from "gears-react";
import {Navigate} from "react-router-dom";
import {path} from "./routers";
import {content_router} from "./content/content_router";
import Footer from "../UI/atoms/Footer";
import {quiz_router} from "./quiz/quiz_router";
import {settings} from "../UI/templates/account/settings";
import {room_routers} from "./room/room_routers";
import {stream_room_routers} from "./stream_room/stream_room_routers";

export const main_routers = getPages([
    [`*`, routes_template(content_router, [{body: <Footer/>}])],

    [path.login, [{body: <Navigate to={'/'}/>}]],
    [path.signup, [{body: <Navigate to={'/'}/>}]],
    [path.settings, settings],

    [`${path.room}/*`, routes_template(room_routers)],
    [`${path.quiz}/*`, routes_template(quiz_router)],

    [`${path.stream_room}/:id/*`, routes_template(stream_room_routers)]
])