import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import {Header} from "../../atoms/Header";
import RowCard from "../../atoms/RowCard";
import {path} from "../../../routers/routers";
import * as React from "react";
import BackButton from "../../atoms/BackButton";
import {MyRow} from "../../atoms/MyRow";
import g8 from '../../assets/images/g8.svg';
import {BigButton} from "../../atoms/BigButton";
import {useWindowSize} from "usehooks-ts";
import NoData from "../../atoms/NoData";
import {BottomPos} from "../../atoms/BottomPos";
import Scroll from "../../atoms/Scroll";
import {HiddenText} from "../../atoms/HiddenText";
import {IQuizSessionRow} from "../../../../QMS/models/quiz_session/responses/IQuizSessionRow";
import {IQuizRow} from "../../../../QMS/models/quiz/responses/IQuizRow";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {width, height} = useWindowSize()

    useEffect(() => {
        store.quiz.list.loader.set(true)
        return () => store.quiz.list.loader.set(true)
    }, [])

    const [againLoader, setAgainLoader] = useState<boolean>(true);

    const [limit, setLimit] = useState<number>(5);
    const [offset, setOffset] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [ordering, setOrdering] = useState<string>('');
    const [question_time_max, setQuestionTimeMax] = useState<number | null>(null);
    const [question_time_min, setQuestionTimeMin] = useState<number | null>(null);

    const array = [0, 0, 0]
    const [list, setList] = useState<IQuizRow[]>([]);

    useEffect(() => {
        store.quiz.list.request({
            limit,
            offset,
            question_time_max: question_time_max!,
            question_time_min: question_time_min!,
            search
        }).then(r => {
            console.log(r)
            if (store.quiz.list.get().results.length > 0) {
                setList([...list, ...store.quiz.list.get().results])
            }
        })
    }, [limit, offset])

    return <div>
        <Header>
            <MyRow>
                <BackButton to={path.account}/>
                <HiddenText>{t("quiz.title")}</HiddenText>
            </MyRow>
            {/*<FilterDrawer/>*/}
        </Header>

        <Root>
            {
                store.quiz.list.loader.get() && againLoader ? array.map((e, i) => <RowCard
                    key={i}/>) : store.quiz.list.get().count > 0 ?
                    <>
                        <Scroll
                            offset={offset} setOffset={setOffset}
                            length={store.quiz.list.get().count}
                            limit={limit} setAgainLoader={setAgainLoader}>
                            {
                                list.map((e, i) =>
                                    <RowCard key={e.id}
                                             img={e.images !== undefined ? e.images[0]?.file : undefined}
                                             title={e.name}
                                             subtitles={[
                                                 {
                                                     main: `${e.question_number}`,
                                                     body: `${t('quiz.list.row.subtitle.questions')}: `
                                                 },
                                                 {
                                                     main: `${e.session_number}`,
                                                     body: `Кол-во сессий: `
                                                 }
                                             ]}
                                             onClick={() => navigate(`${e.id}${path.questions}`)}
                                             action={() => {
                                                 navigate(`${e.id}`)
                                             }}
                                             // ticker={true}
                                    />
                                )
                            }
                        </Scroll>


                        <BottomPos>
                            <BigButton
                                fullWidth
                                variant="outlined"
                                sx={{
                                    backgroundColor: 'white'
                                }}
                                onClick={() => navigate(`${path.quiz}${path.history}`)}
                            >
                                {t("quiz.button.history")}
                            </BigButton>
                            <BigButton
                                fullWidth
                                variant="contained"
                                onClick={() => navigate(`${path.quiz}${path.create}`)}
                            >
                                {t("quiz.button.create")}
                            </BigButton>
                        </BottomPos>

                    </>
                    :
                    <NoData
                        height={height}
                        title={`${t("quiz.list.nodata.title")}`}
                        subtitle={`${t("quiz.list.nodata.subtitle")}`}
                        src={g8}
                        onClick={() => {
                            navigate(`${path.quiz}${path.create}`)
                        }}
                        button_title={`${t("quiz.list.nodata.button")}`}
                        img_width={'192px'}
                    />
            }
        </Root>
    </div>
});

const template: ITemplate = {
    body: <Body/>
}

export const quiz_list = [template];

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: calc(16px + var(--header-height)) 16px calc(20vh + 50px) 16px;
`