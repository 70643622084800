import React from 'react';
import image from '../assets/images/bro.svg'

const NoInternetConnection = () => {
    return (
        <div>
            NoInternetConnection
        </div>
    );
};

export default NoInternetConnection;