import {observer} from "mobx-react";
import styled from "styled-components";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import {Context} from "../../../context";
import AccountNavigateButton from "../../atoms/AccountNavigateButton";
import {Avatar} from "@mui/material";
import {path} from "../../../routers/routers";
import {useProgressiveImage} from "../../../hooks/useProgressiveImage";
import AccountExitButton from "../../atoms/AccountExitButton";

const Body = observer(() => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const loaded = useProgressiveImage(store.account.details.get().avatar)

    return <Root>
        <Links>
            <ProfileInfo>
                <Avatar
                    src={loaded}
                    sx={{width: 72, height: 72}}
                />
                <Name>{store.account.details.get().username}</Name>
                {/*<Position>Salesman</Position>*/}
            </ProfileInfo>

            <AccountNavigateButton title={`${t("account.button.quizzes.button.title")}`}
                                   subtitle={`${t("account.button.quizzes.button.subtitle")}`}
                                   onClick={() => navigate(path.quiz)}/>

            {/*<AccountNavigateButton title={'My quiz session'}*/}
            {/*                       subtitle={'See list of quiz session'}*/}
            {/*                       onClick={() => navigate(`${path.room}${path.org}`)}/>*/}


            {/*<AccountNavigateButton title={'Edit Profile '}*/}
            {/*                       subtitle={'You can change notification, payment detail and, more'}*/}
            {/*                       onClick={() => navigate(path.settings)}/>*/}

            <AccountExitButton title={`${t("account.button.exit.button.title")}`}
                               subtitle={`${t("account.button.exit.button.subtitle")}`}
                               onClick={() => {
                                   localStorage.clear()
                                   window.location.reload()
                               }}/>
        </Links>

    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const account = [template];

const Root = styled.div`
  width: 100%;
  padding: 16px 16px calc(20vh + 50px) 16px;
  position: relative;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0 0 0;
`;

const ProfileInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
`;

const Position = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--gray);
`;
