import React from 'react';
import styled from "styled-components";
import {CircularProgress} from "@mui/material";

const Loader = () => {
    return (
        <Root>
            <CircularProgress color="primary" />
        </Root>
    );
};

export default Loader;

const Root = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
`