import {makeAutoObservable} from "mobx";
import {ServiceStore} from "gears-react";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {QuizCategory} from "../services/quiz_category";
import {IQuizCategoryRow} from "../models/quiz_category/responses/IQuizCategoryRow";
import {IGetAll} from "../models/quiz_category/params/IGetAll";
import {ID} from "../models/@others/ID";

export class QuizCategoryStore {
    constructor() {
        makeAutoObservable(this);
    }

    public readonly list = new ServiceStore<[IGetAll], IListDataResponse<IQuizCategoryRow>>(QuizCategory.get_all, true);
    public readonly details = new ServiceStore<[ID], IQuizCategoryRow>(QuizCategory.get, true);

}