import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {MyRow, MyRowSpaceBetween} from "./MyRow";
import BackButton from "./BackButton";
import {path} from "../../routers/routers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {ArrowDown2, Send2, TickCircle} from "iconsax-react";
import {MyTextField,} from "./MyTextField";
import {InputAdornment} from "@mui/material";
import {SendTextField} from "./SendTextField";
import CommentItem from "./CommentItem";

type Props = {
    setIsChat: Dispatch<SetStateAction<boolean>>
}

const LiveChat = ({setIsChat}: Props) => {
    return (
        <Root>
            <MyRowSpaceBetween2>
                <MyRow>
                    <p>Live Chat</p>
                </MyRow>
                <ArrowDown2
                    size="24"
                    color="#262626"
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => setIsChat(false)}
                />
            </MyRowSpaceBetween2>


            <Comments>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
                <CommentItem/>
            </Comments>


            <ChatFooter>
                <SendTextField
                    fullWidth

                    variant="filled"
                    type={'text'}

                    InputProps={{
                        placeholder: 'Say something',
                        autoFocus: false,
                        autoComplete: 'off',
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Send2
                                    size="24"
                                    color="#1DA1F2"
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </ChatFooter>

        </Root>
    );
};

export default observer(LiveChat);

const Root = styled.div`
  width: 100%;
`;

const ChatFooter = styled.div`
  position: fixed;
  bottom: 0;
  //height: 56px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(127, 146, 160, 0.25);
  width: 100%;
  padding: 10px 16px;
  //filter: drop-shadow(0px -2px 10px rgba(38, 38, 38, 0.25));
`;

const Comments = styled.div`

`;

const MyRowSpaceBetween2 = styled(MyRowSpaceBetween)`
  padding: 16px;
  box-shadow: 0px 2px 10px rgba(127, 146, 160, 0.25);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99999;
  background-color: white;
`;
