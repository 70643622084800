import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import Centrifuge from "centrifuge";
import {socket} from "../../../../QMS/endpoints/ws";
import {Context} from "../../../context";

type Props = {
    defaultValue: number;
}

const SessionParticipant = ({defaultValue}: Props) => {
    const {store} = useContext(Context);
    const centrifuge = new Centrifuge(socket.connect());

    const [participant, setParticipant] = useState<number>(defaultValue)

    useEffect(() => {
        setParticipant(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        centrifuge.setToken(store.quiz_session.details.get().cent_token)

        let callbacks = {
            "publish": function (ctx: any) {
                console.log(ctx)
                if (ctx?.data?.reason === 'new_participant') {
                    setParticipant(ctx?.data?.result?.number)
                }
            },
        }
        centrifuge.subscribe(`quiz_session_creator.${store.quiz_session.details.get().id}`, callbacks);

        centrifuge.connect();
    }, [])

    return (
        <>
            {participant}
        </>
    );
};

export default observer(SessionParticipant);