import React, {Dispatch, SetStateAction} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import AnswerInput from "./AnswerInput";
import {IQuizAnswer} from "../../../QMS/models/quiz_question_answer/params/IQuizAnswer";


type Props = {
    answers: IQuizAnswer[],
    setAnswers: Dispatch<SetStateAction<IQuizAnswer[]>>
}

const Answers = ({answers, setAnswers}: Props) => {

    const onChange = (e: any, index: number) => {
        e.preventDefault()
        let array = answers.filter(function (v1, i) {
            if (i === index) {
                v1.text = e.target.value
            }
            return v1
        })
        setAnswers(array)
    }

    const onChangeCorrectAnswer = (e: any, index: number) => {
        e.preventDefault()
        let array = answers.filter(function (v1,i) {
            if (i === index) {
                v1.correct_answer = !v1.correct_answer
            } else {
                v1.correct_answer = false
            }
            return v1
        })
        setAnswers(array)
    }

    return (
        <Root>
            {
                answers.map((answer, index) => <AnswerInput
                    key={index}
                    correct_answer={answer.correct_answer}
                    onChange={onChange}
                    onChangeCorrectAnswer={onChangeCorrectAnswer}
                    index={index}
                    text={answer.text}

                />)
            }
        </Root>
    );
};

export default observer(Answers);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`