import {AxiosResponse} from "axios";
import {IListDataResponse} from "../models/@others/IListDataResponse";
import {$qms} from "../http";
import {quiz, quiz_session} from "../endpoints/qms";
import {IGetAll} from "../models/quiz_session/params/IGetAll";
import {IQuizSessionRow} from "../models/quiz_session/responses/IQuizSessionRow";
import {IGet} from "../models/quiz_session/params/IGet";
import {IConnectResponse} from "../models/quiz_session/responses/IConnectResponse";
import {IConnectParams} from "../models/quiz_session/params/IConnectParams";
import {ID} from "../models/@others/ID";
import {IUpdateParticipantNameResponse} from "../models/quiz_session/responses/IUpdateParticipantNameResponse";
import {IName} from "../models/@others/IName";
import {IResultResponse} from "../models/quiz_question/responses/IResultResponse";
import {IShowResult} from "../models/quiz_question/params/IShowResult";
import {IShowMyResultParams} from "../models/quiz_session/params/IShowMyResultParams";


export class QuizSession {

    static async get_all({
                             limit,
                             offset,
                             ordering,
                             search,
                             question_time,
                             category,
                             history,
                             i_part,
                             i_org
                         }: IGetAll): Promise<AxiosResponse<IListDataResponse<IQuizSessionRow>>> {
        return await $qms.get<IListDataResponse<IQuizSessionRow>>(quiz_session.get_all(), {
            params: {
                limit,
                offset,
                ordering,
                search,
                question_time,
                category,
                history,
                i_part,
                i_org
            },
        })
    }

    static async get({
                         history,
                         id,
                         i_part,
                         i_org
                     }: IGet): Promise<AxiosResponse<IQuizSessionRow>> {
        return await $qms.get<IQuizSessionRow>(quiz_session.get(id), {
            params: {
                history,
                i_part,
                i_org
            }
        })
    }

    static async close_registration({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session.close_registration(id))
    }

    static async close({id}: ID): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session.close(id))
    }

    static async connect({id}: ID, {name, code}: IConnectParams): Promise<AxiosResponse<IConnectResponse>> {
        return await $qms.post<IConnectResponse>(quiz_session.connect(id), {name, code})
    }

    static async update_participant_name(
        {id}: ID,
        {name}: IName,
    ): Promise<AxiosResponse<IUpdateParticipantNameResponse>> {
        return await $qms.patch<IUpdateParticipantNameResponse>(quiz_session.update_participant_name(id), {
            name
        })
    }

    static async reconnect({id}: ID): Promise<AxiosResponse<IConnectResponse>> {
        return await $qms.get<IConnectResponse>(quiz_session.reconnect(id))
    }

    static async show_my_result(
        {id, history}: IShowMyResultParams
    ): Promise<AxiosResponse<IResultResponse>> {
        return await $qms.get<IResultResponse>(quiz_session.show_my_result(id), {
            params: {
                history
            }
        })
    }

    static async show_question_result(
        {id}: ID,
    ): Promise<AxiosResponse<IListDataResponse<IResultResponse>>> {
        return await $qms.get<IListDataResponse<IResultResponse>>(quiz_session.show_question_result(id))
    }

    static async show_result(
        {id}: ID,
        {offset, ordering, search, limit, question_time, i_part, i_org, history}: IShowResult
    ): Promise<AxiosResponse<IListDataResponse<IResultResponse>>> {
        return await $qms.get<IListDataResponse<IResultResponse>>(quiz_session.show_result(id), {
            params: {
                offset, ordering, search, limit, question_time, i_part, i_org, history
            }
        })
    }

    static async is_creator(
        {id}: ID,
    ): Promise<AxiosResponse> {
        return await $qms.get(quiz_session.is_creator(id))
    }

    static async participant(
        {id}: ID,
    ): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session.participant(id))
    }

    static async calculate_places(
        {id}: ID,
    ): Promise<AxiosResponse> {
        return await $qms.patch(quiz_session.calculate_places(id))
    }




}