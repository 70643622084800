import {observer} from "mobx-react";
import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {Context} from "../../context";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ITemplate} from "gears-react";
import PageLinkCard from "../atoms/PageLinkCard";
import {path} from "../../routers/routers";
import {Header} from "../atoms/Header";
import logo_img from '../assets/images/HeaderLogo.svg';

const Body = observer(() => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {store} = useContext(Context);

    return <Root>
        <Header>
            <Logo src={logo_img}/>
        </Header>
        <Content>
            <PageLinkCard title={`${t("content.link.quizzes.title")}`} onClick={() => navigate(path.session)}
                          subtitle={`${t("content.link.quizzes.subtitle")}`}
            />
            <PageLinkCard title={`${t("content.link.streams.title")}`} onClick={() => navigate(`${path.stream}${path.list}`)}
                          subtitle={`${t("content.link.streams.subtitle")}`}
            />
        </Content>
    </Root>
});

const template: ITemplate = {
    body: <Body/>
}

export const content = [template]

const Root = styled.div`
  width: 100%;
  padding-top: calc(var(--header-height) + 16px);
`;

const Logo = styled.img`
  width: 68px;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 163px);
  grid-gap: 16px;
  padding: 0 16px;
  justify-content: center;

  @media (max-width: 552px) {
    justify-content: center;
  }

  @media (max-width: 429px) {
    grid-template-columns: repeat(auto-fill, 173px);
  }

  @media (max-width: 411px) {
    grid-template-columns: repeat(auto-fill, minmax(30vw, 170px));
  }
  @media (max-width: 387px) {
    grid-template-columns: repeat(auto-fill, minmax(30vw, 160px));
  }

  @media (max-width: 367px) {
    grid-template-columns: repeat(auto-fill, minmax(30vw, 150px));
  }

  @media (max-width: 352px) {
    grid-template-columns: repeat(auto-fill, minmax(30vw, 130px));
  }


  //@media (min-width: 319px) {
  //  grid-template-columns: repeat(auto-fill, minmax(30vw, 130px));
  //  justify-content: center;
  //}

`;
